import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { TodoDBService } from '../todoDB.service';
import { List } from '../elements/list';

@Component({
  selector: 'app-list-options-dialog',
  templateUrl: './list-options-dialog.component.html',
  styleUrls: ['./list-options-dialog.component.css']
})
export class ListOptionsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ListOptionsDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public list: List, public todoDBService: TodoDBService, public dialog: MatDialog,
    public zone: NgZone) { }

  ngOnInit(): void {
  }

  deleteList() {
    if (this.todoDBService.CurrentUser.getNumberOfLists()<=1) {
      this.dialog.open(InformationDialogComponent, {data: {title: "Information", text: "Cannot delete - You must always have at least one list"}});
      return;
    }


    const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {element: "list", title: this.list.title}});

    confirmationDialogRef.afterClosed().subscribe(result => {
        if (result=="confirmed")
          this.list.delete();
          this.dialogRef.close();
    });
  }

  duplicateList() {
    this.todoDBService.CurrentUser.duplicateList(this.list);
  }
}
