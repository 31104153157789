import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListContainerComponent } from './list-container/list-container.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageAdminComponent } from './page-admin/page-admin.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageExamplesComponent } from './page-examples/page-examples.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageNewsComponent } from './page-news/page-news.component';
import { PageTemplatesComponent } from './page-templates/page-templates.component';


const routes: Routes = [
 
  { path: 'list/:id', component: ListContainerComponent, pathMatch: 'full' },
  { path: 'user', component: UserLoginComponent, pathMatch: 'full' },
  { path: 'user/:action', component: UserLoginComponent, pathMatch: 'full'  },
  { path: 'about', component: PageAboutComponent, pathMatch: 'full'  },
  { path: 'admin', component: PageAdminComponent, pathMatch: 'full'  },
  { path: 'contact', component: PageContactComponent, pathMatch: 'full'  },
  { path: 'examples', component: PageExamplesComponent, pathMatch: 'full'  },
  { path: 'home', component: PageHomeComponent, pathMatch: 'full'  },
  { path: 'news', component: PageNewsComponent, pathMatch: 'full'  },
  { path: 'templates', component: PageTemplatesComponent, pathMatch: 'full'  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageHomeComponent, pathMatch: 'full' }, //Later - PageNotFoundComponent
  //Use nested routes to load overall page, and router-outlet within that to load lists page, user options, homepage, etc

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload'} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
