<!-- <div #listBlackoutElement style="display: {{blackoutElementDisplay}}" class="list-blackout"></div>-->
<h1 mat-dialog-title>COLUMNS IN "{{this.data.list.title}}"</h1>

<hr/>

<div mat-dialog-content class="column-options-card-container" >

    <div *ngIf="this.data.listPermissions.canView" style="position: relative">
        <!-- <button mat-stroked-button color="primary" class="button-style" (click)="reorderColumnsToggle()" [disabled]="this.listPermissions.readOnly">
            <mat-icon>view_column</mat-icon> Columns
        </button> -->
        <div class="reorder-columns-card" cdkDropList (cdkDropListDropped)="dropColumn($event)" [cdkDropListDisabled]="this.data.listPermissions.readOnly">
            
            <section *ngIf="this.todoDBService.CurrentUser && this.data.list"> <!-- To prevent errors trying to acccess list before its loaded --> 
                <!-- <mat-checkbox class="column-checkbox" color="primary" [(ngModel)]="this.list.showOptionsColumn" (change)="this.list.updateServer(false)"> -->
                <mat-checkbox class="column-checkbox" color="primary" [checked]="this.data.list.showOptionsColumn" (change)="this.data.list.showOptionsColumn = !this.data.list.showOptionsColumn; this.data.list.updateServer(false)"
                [disabled]="this.data.listPermissions.readOnly">
                    <span class="column-checkbox-text">
                        Row options
                    </span>
                </mat-checkbox>
                <!-- <mat-checkbox class="column-checkbox" color="primary" [(ngModel)]="this.list.showRowNumberColumn" (change)="this.list.updateServer(false)"> -->
                <mat-checkbox class="column-checkbox" color="primary" [checked]="this.data.list.showRowNumberColumn" (change)="this.data.list.showRowNumberColumn = !this.data.list.showRowNumberColumn; this.data.list.updateServer(false)"
                [disabled]="this.data.listPermissions.readOnly">
                    <span class="column-checkbox-text">
                        Row numbers
                    </span>
                </mat-checkbox>
                <hr/> 
                <section class="column-drag-container">
                    <div class="column-checkbox-container" *ngFor="let column of this.data.list.Columns" cdkDrag cdkDragLockAxis="y" cdkDragBoundary=".column-drag-container">
                        <div *ngIf="!column.deleted">
                            <!-- <mat-checkbox class="column-checkbox" color="primary" [(ngModel)]="column.enabled" (change)="column.updateServer(false)"> -->
                            <mat-checkbox class="column-checkbox" color="primary" [checked]="column.enabled" (change)="column.enabled = !column.enabled; column.updateServer(false)"
                            [disabled]="this.data.listPermissions.readOnly">
                                <span class="column-checkbox-text">
                                    {{column.title}}
                                </span>
                            </mat-checkbox>
                            <button class="column-checkbox-settings" mat-icon (click)="columnOptionsDialog(column)"
                            [disabled]="this.data.listPermissions.readOnly">
                                <mat-icon class="column-checkbox-settings-icon" color="primary" [inline]="true">settings</mat-icon>
                            </button>
                        </div>
                    </div>
                </section>
            </section>  
            <br/>
            <div class="reorder-columns-actions">
                <button mat-stroked-button color="primary" (click)="addColumn()" [disabled]="this.data.listPermissions.readOnly">
                    <mat-icon>system_update_alt</mat-icon> New column
                </button>

            </div>
        </div>
    </div>

</div>

<hr/>
<div mat-dialog-actions>
    <div style="overflow: auto;">
        
        <button mat-stroked-button color="primary" class="button-style cancel-button" mat-dialog-close>
            <mat-icon>clear</mat-icon> Close
        </button>
    </div>
</div>