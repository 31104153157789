import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { KeyValue } from '@angular/common';
import { TodoDBService } from '../todoDB.service';
import { Column } from '../elements/column';

@Component({
  selector: 'app-list-column-options-dialog',
  templateUrl: './list-column-options-dialog.component.html',
  styleUrls: ['./list-column-options-dialog.component.css']
})
export class ListColumnOptionsDialogComponent implements OnInit {

  columnTitleEditing = "";
  columnDataTypeEditing = "";
  dataType = null;

  constructor(public dialogRef: MatDialogRef<ListColumnOptionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public column: Column,
    public todoDBService: TodoDBService, public dialog: MatDialog, public zone: NgZone) { }

  ngOnInit(): void {
    this.columnTitleEditing = this.column.title;
    this.columnDataTypeEditing = this.column.dataType;
    this.dataType = Column.DataType;
  }

  removeColumn() {
    //Use dialog confirmation to confirm delete
    this.zone.run(() => {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {element: "column", title: this.column.title}});

      confirmationDialogRef.afterClosed().subscribe(result => {
          if (result=="confirmed")
            this.column.delete();
            this.dialogRef.close();
      });
    }); 
  }

  closeAndSaveOptionsDialog(): void {
    if (this.columnTitleEditing == "")
      return;

    this.column.title = this.columnTitleEditing;
    this.column.dataType = this.columnDataTypeEditing;   
    this.column.updateServer(false); 
    //this.todoService.updateLocalColumnProperties(this.column, "title", this.column.title);
    //this.todoService.updateLocalColumnProperties(this.column, "dataType", this.column.dataType);

    //this.todoService.updateColumnOnServer(this.column, true);


    this.dialogRef.close();
  }

    /* keyvalue pipe inputs for DataType values */ 
  // Order by ascending property value
  valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.value.localeCompare(b.value); 
  }

  // Order by descending property key
  keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }
}
