<div class="page-base">
    <div class="page-header">
        Contact
    </div>


    <div class="page-align-center">
        <p>
            You can email <a href="mailto:hello@exenea.com?subject=Hello Exenea!">hello@exenea.com</a> if you have any comments or questions.
        </p>

        <p>
            Alternatively, you can also use <a href="https://www.facebook.com/Exenea-103712451445973">Facebook</a> or <a href="https://twitter.com/ExeneaDo">Twitter</a> to get in touch.
        </p>

        <p>
            Important updates, such as new features, will also be made available on the <a (click)="this.router.navigate(['home']);">Exenea newsletter</a>.
        </p>
    </div>
</div>
