import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, ApplicationRef } from '@angular/core';
import { CdkDragDrop, CdkDropList, moveItemInArray, CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';


import { MatButton } from '@angular/material/button';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateSelectionDialogComponent, DateSelectionData } from '../date-selection-dialog/date-selection-dialog.component';
import { EventEmitter } from '@angular/core';
import { ListRowCardDialogComponent } from '../list-row-card-dialog/list-row-card-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { KeyValue } from '@angular/common';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
//import { ListPermissions } from '../list-container/list-container.component';
import { List, ListPermissions } from '../elements/list';
import { Row } from '../elements/row';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';

@Component({
  selector: 'app-list-items',
  templateUrl: './list-items.component.html',
  styleUrls: ['./list-items.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListItemsComponent implements OnInit {

  @Input() list: List;
  @Input() listPermissions: ListPermissions;
  //@Input() nestingDepth: number;

  contextMenuMouseOutCount = 0;

  blackoutClosedEventEmitter = new EventEmitter<any>();

  //parentRowId: string = "0";
  /*currentCellEdit: HTMLTextAreaElement = null;*/
  currentRowMenu: Row = null;
  contextMenuDisableIndent = false;
  contextMenuDisableSubRow = false;
  contextMenuIsHighlighted = false;
  contextMenuIsTextRow = false;

  originalExpandValue: boolean = false;
  testDate = new Date(1990, 0, 1);
  
  constructor(public todoDBService: TodoDBService, public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef, public zone: NgZone) {  //, public dialog: MatDialog
    TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes
    });
  }

  ngOnInit(): void {
    this.log("Initiated")
    //this.parentRowId = (this.parentRow) ? this.parentRow.id : "0";
    //this.log("parentRowId: "+this.parentRowId+"; nestingDepth: "+this.nestingDepth);
  }

  ngOnDestroy(): void {
    this.log("ngDestroy() called");

    this.blackoutClosedEventEmitter.unsubscribe();
    this.currentRowMenu = null;

    this.log("ngDestroy() procedures complete");
  }

  log(message: string): void {
    console.log("> ListItemsComponent: " + message)
  }



  clickBlackout(blackoutElement: HTMLElement): void {
    blackoutElement.style.display = "none";
    this.blackoutClosedEventEmitter.emit("clickBlackout");
    TriggerDataRefreshEvent.emit("list items");
  }

  /* Context menu functions - Start */

  openContextMenu(event:MouseEvent, contextMenu: HTMLElement, row: Row, rowContainer: HTMLElement): void {
    if (contextMenu.style.display == "inline") {  //Already open
      this.closeContextMenu(null, contextMenu);
      if (row.id == this.currentRowMenu.id)
        return;
    }

    TriggerDataRefreshEvent.emit("list items");
    contextMenu.style.display = "inline";
    //contextMenu.style.top = event.pageY + "px";
    contextMenu.style.top = (rowContainer.offsetTop + rowContainer.offsetHeight) + "px";

    this.currentRowMenu = row;
    this.contextMenuDisableIndent = false;
    this.contextMenuDisableSubRow = false;
    
    this.contextMenuDisableIndent = !this.list.rowHasSameParentAsAbove(row);
    if (this.list.getRowLevel(row)>=3) {
      this.contextMenuDisableIndent = true;
      this.contextMenuDisableSubRow = true;
    }
    this.contextMenuIsHighlighted = row.isHighlighted;
    this.contextMenuIsTextRow = row.isTextRow;

    this.log("Click: "+event.clientY)
    TriggerDataRefreshEvent.emit("list items");
  }

  //closeContextMenu(event:MouseEvent, contextMenu: HTMLElement): void {
  closeContextMenu(event:MouseEvent, contextMenu: HTMLElement): void {
    /*if (this.contextMenuMouseOutCount < 10) {
      this.contextMenuMouseOutCount++;
      return;
    }*/
    /*
    let tolerance = 0; 
    
    let mouseX = event.
    let mouseY = event.screenY;

    let menuX = contextMenu.offsetLeft + contextMenu.offsetTop
    let menuY = contextMenu.offsetTop+ contextMenu.offsetParent.clientTop;
    

    let menuXEnd = menuX + contextMenu.offsetWidth;
    let menuYEnd = menuY + contextMenu.offsetHeight;

    this.log("mouseX="+mouseX+", mouseY="+mouseY+", menuX="+menuX+", menuY="+menuY+", menuXEnd="+menuXEnd+", menuYEnd="+menuYEnd);

    if (menuX == 0 && menuY == 0)
      return;

    if (mouseX < (menuX - tolerance) || mouseX > (menuXEnd + tolerance) || mouseY < (menuY - tolerance) || mouseY > (menuYEnd + tolerance)) {
      contextMenu.style.display = "none";//this.log("Out of bounds, closing menu");//
      //deleteButton._elementRef.nativeElement.innerText = "Delete row";
      this.contextMenuMouseOutCount = 0;
    }
    TriggerDataRefreshEvent.emit("list items");*/
    if (!event) 
      contextMenu.style.display = "none";
    else {
      contextMenu.style.display = "none";

    }    
    
    TriggerDataRefreshEvent.emit("list items");
  }

  contextMenuAddRowAbove() {
    this.log("Add new row above: " + this.currentRowMenu.id);
    //this.todoService.addNewRow(this.currentRowMenu, false, this.todoService.getRowDataById(this.currentRowMenu.parent));
    this.list.addNewRow(this.currentRowMenu, false, this.list.getRowById(this.currentRowMenu.parent));
  }

  contextMenuAddRowBelow() {
    this.log("Add new row below: " + this.currentRowMenu.id);
    //this.todoService.addNewRow(this.currentRowMenu, true, this.todoService.getRowDataById(this.currentRowMenu.parent));
    this.list.addNewRow(this.currentRowMenu, true, this.list.getRowById(this.currentRowMenu.parent));
  }

  contextMenuRemoveRow (contextMenu: HTMLElement) {
    this.log("Remove row: " + this.currentRowMenu.id);
    
    this.zone.run(() => {
      const confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {element: "row", title: null}});

      confirmationDialogRef.afterClosed().subscribe(result => {
          if (result=="confirmed")
            //this.todoService.removeRow(this.currentRowMenu);
            this.currentRowMenu.delete();
            //this.dialogRef.close();
      });
    });
  
  }

  contextMenuNewSubRow() {
      //this.todoService.addNewSubRow(this.currentRowMenu);
      //this.todoService.addNewRow(this.currentRowMenu, true, this.currentRowMenu);
      this.list.addNewRow(this.currentRowMenu, true, this.currentRowMenu);
  }


  /* Context menu functions - End */

  //Set expanded=true/false for immediate children
  toggleSubRowsDisplay(event: MouseEvent, parentRow: Row) {
    //this.todoService.toggleRowExpand(parentRow);
    parentRow.toggleRowExpand(this.listPermissions);
    this.list.updateProperties_rows();
  }
  
  dragStarted(event: CdkDragStart<String[]>) {
    
  }

  dragMoved(event: CdkDragMove<String[]>) {
    document.body.style.cursor = "move";   
    

  } 

  dropRow(event: CdkDragDrop<string[]>): void {
    let previousIndexInLevel = event.previousIndex;
    let currentIndexInLevel = event.currentIndex;
  
    document.body.style.cursor = "default";
    
    let distance = event.distance;/* Temporarily removed while changing to row indents instead of nesting
    if (currentIndexInLevel == previousIndexInLevel){ //Workaround for when list is only 2 items and container isn't big enough to recognise drag
      if (distance.y>20 && currentIndexInLevel < this.todoService.getRowDataByParent(this.parentRow).length-1) currentIndexInLevel++;
      if (distance.y<-20 && currentIndexInLevel>0) currentIndexInLevel--;
    }*/
    
    if (currentIndexInLevel == previousIndexInLevel) //Don't process if there is no change
      return;

    this.log("Drop event occurred: previousIndexInLevel="+previousIndexInLevel+", currentIndexInLevel="+currentIndexInLevel);    
    this.log("Distance: "+distance.x+","+distance.y);
    this.log("Previous container: "+event.previousContainer.element.nativeElement.className+", new container: "+event.container.element.nativeElement.className);
    this.log("Previous data: "+event.previousContainer.data+", new data: "+event.container.data);

    //this.todoService.moveRow(previousIndexInLevel, currentIndexInLevel);
    this.list.moveRow(previousIndexInLevel, currentIndexInLevel);
    
  } 

  indentRow() {
    //if (this.currentRowMenu.RowHasSameParentAsAbove)
    //  this.todoService.inheritRowAboveParent(this.currentRowMenu);

    if (this.list.rowHasSameParentAsAbove(this.currentRowMenu))
      this.list.inheritRowAboveParent(this.currentRowMenu);
  }

  openRowCard() {
    this.zone.run(() => {
      this.dialog.open(ListRowCardDialogComponent, {data: {list: this.list, row: this.currentRowMenu, listPermissions: this.listPermissions}});
    });
  }

  toggleHighlight() {
    //this.currentRowMenu.isHighlighted = !this.currentRowMenu.isHighlighted;
    //this.todoService.updateLocalRowProperties(this.currentRowMenu, "isHighlighted", this.currentRowMenu.isHighlighted);
    this.currentRowMenu.toggleHighlight();
    this.contextMenuIsHighlighted = this.currentRowMenu.isHighlighted;
  }

  toggleTextRow() {
    //this.currentRowMenu.isTextRow = !this.currentRowMenu.isTextRow;
    //this.todoService.toggleRowTextRow(this.currentRowMenu);
    //this.todoService.updateLocalRowProperties(this.currentRowMenu, "isTextRow", this.currentRowMenu.isTextRow);
    this.currentRowMenu.toggleTextRow();
    this.contextMenuIsTextRow = this.currentRowMenu.isTextRow;
  }

  toggleTextRowBold() {
    //this.currentRowMenu.textRowIsBold = !this.currentRowMenu.textRowIsBold;
    //this.todoService.toggleTextRowBold(this.currentRowMenu);
    //this.todoService.updateLocalRowProperties(this.currentRowMenu, "textRowIsBold", this.currentRowMenu.textRowIsBold);
    this.currentRowMenu.toggleTextRowBold();
  }

  toggleTextRowItalic() {
    //this.currentRowMenu.textRowIsItalic = !this.currentRowMenu.textRowIsItalic;
    //this.todoService.updateRowOnServer(this.currentRowMenu, true);
    //this.todoService.updateAllProperties();
    //this.todoService.updateLocalRowProperties(this.currentRowMenu, "textRowIsItalic", this.currentRowMenu.textRowIsItalic);
    this.currentRowMenu.toggleTextRowItalic();
  }

  duplicateRow() {
    //this.todoService.duplicateRow(this.currentRowMenu);
    //this.todoService.updateAllProperties();
    this.list.duplicateRow(this.currentRowMenu);
  }

  /* keyvalue pipe inputs for DataType values */ 
  // Order by ascending property value
  valueAscOrder = (a, b): number => {
    //return a.value.localeCompare(b.value); 
    
    return a.value.position - b.value.position;
    //return -1;
  }

  /*getRowClass(row: Row): string {
    //this.log("TEMPLATE FUNCTION CALL - getRowClass");
    let rowClass = "list-row list-row"+this.list.getRowLevel(row);

    if (row.isHighlighted)
      rowClass += " list-row-highlight";

    return rowClass;
  }*/
}
