<div *ngIf="this.list && this.listPermissions.canView" class="list-column-header-row" [style.width.px]="this.list.totalColumnWidth" 
[style.background]="this.todoDBService.constants_colours.PageBackgroundAccent1"
[style.color]="this.todoDBService.constants_colours.TextLight"
cdkDropList >
    <div *ngIf="this.list.showOptionsColumn"  class="list-row-options-header">
        
    </div>
    <div *ngIf="this.list.showRowNumberColumn" class="list-row-number-header">
        
    </div>
    <div  class="list-row-toggles-header">
        
    </div>

    <div *ngFor="let column of this.list.Columns"> <!-- Cycle through each of the columns -->
        <div *ngIf="column.enabled && !column.deleted" class="list-column-header" [style.width.px]="column.width">
            {{ column.title }}
                <div *ngIf="!this.listPermissions.readOnly" 
                class="list-column-header-drag" 
                cdkDrag cdkDragLockAxis="x" 
                (cdkDragMoved)="dragMoved($event, column)"
                (cdkDragDropped)="dragCompleted($event, column)">
                    
                </div>
        
        </div>
    </div>


</div>

<!-- LIST PROPERTIES --> 
<div *ngIf="this.list && this.listPermissions.canView" [style.width.px]="this.list.totalColumnWidth"
id="list-properties" >
    <mat-accordion (click)="expandClicked()">  
        <mat-expansion-panel hideToggle  class="list-properties-panel"
        [style.background]="this.todoDBService.constants_colours.PageBackgroundAccent1"
        [style.color]="this.todoDBService.constants_colours.TextLight">
            <mat-expansion-panel-header class="list-properties-header">
                <mat-panel-title [style.color]="this.todoDBService.constants_colours.TextLight" class="list-properties-title">
                    <mat-icon>menu</mat-icon> <span class="list-properties-title-text">List properties</span>
                </mat-panel-title>
                <mat-panel-description [style.color]="this.todoDBService.constants_colours.TextLight" class="list-properties-title">
                    
                </mat-panel-description>
            </mat-expansion-panel-header>

            <!-- LIST PROPERTIES CONTENT -->
            <br/>
            Description:
            <textarea class="list-description" matInput
            placeholder="Description..."
            [(ngModel)]="this.list.listDescription" rows=3 
            [disabled]="this.listPermissions.readOnly"
            (blur)="this.list.updateServer(false)"></textarea>

            <br/>
            <br/>
            <span [ngSwitch]="this.list.listStatus">
                <mat-icon class="check-base check-yes" *ngSwitchCase="'yes'" (dblclick)="updateListStatus(this.list, 'blank')">check_circle_outline</mat-icon>
                <mat-icon class="check-base check-warning" *ngSwitchCase="'warning'" (dblclick)="updateListStatus(this.list, 'yes')">error_outline</mat-icon>
                <mat-icon class="check-base check-inprogress" *ngSwitchCase="'in progress'" (dblclick)="updateListStatus(this.list, 'warning')">flip_camera_android</mat-icon>
                <mat-icon class="check-base check-no" *ngSwitchCase="'no'" (dblclick)="updateListStatus(this.list, 'in progress')">highlight_off</mat-icon>
                <mat-icon class="check-base" *ngSwitchDefault (dblclick)="updateListStatus(this.list, 'no')">radio_button_unchecked</mat-icon>
            </span>
            <span> 
                Status
            </span>

            <br/>
            <br/>
            <mat-checkbox color="primary" [checked]="this.list.isPublic" 
            class="checkbox-base"
            [disabled]="this.listPermissions.readOnly"
            (change)="this.list.isPublic = !this.list.isPublic; this.list.updateServer(false)">
            </mat-checkbox>
            Public list?
        

        </mat-expansion-panel>
    </mat-accordion>
</div>
     