<div class="page-base">
    <div class="page-header">
        News
    </div>

    <div class="news-container">
        <div class="news-post-container">
            <div id="introduction20200811" class="news-post-title">Introducing... <span class="logo-font-style news-logo-header">exenea</span></div>
            <div class="news-post-information">Posted: 11 August 2020</div>

            <p class="news-post-header margin-top-10">
                What is Exenea?
            </p>

            <p>
                Exenea aims to be a new force in the world of productivity management.
            </p>

            <p>
                At it's core, Exenea is a list management platform, but it is so much more than just that.
            </p>

            <p>
                Exenea will allow you to create lists with customised layouts so that you can keep track of all necessary information relating to your projects, for example by adding columns to represent the status of various lifecycles of each item within the list, while avoiding complexity and maintaining ease of use.
            </p>

            <p>
                Lists can be made shareable in order for others to collaborate on projects with you, and permissions on lists will be at a very granular level to give you maximum control. 
            </p>

            <p class="news-post-header">
                Why do I need Exenea?
            </p>

            <p>
                Exenea was created as a result of limitations and inadequacies in traditional list-making and productivity platforms. If you have ever wished you could add some columns, customise the way data is displayed, collaborate with others, and have control over how lists are shared with overs, then Exenea is for you.
            </p>

            <p class="news-post-header">
                What could I use Exena for?
            </p>

            <p>
                Exenea is highly customisable and useful in a wide array of scenarios, such as the following:
            </p>

            <ul>
                <li>Simple to-do lists</li>
                <li>Advanced to-do lists with multiple action points for each item</li>
                <li>Managing projects or teams of people</li>
                <li>Keeping track of client feedback, client information requests, or outstanding information</li>
                <li>Planning personal and business projects</li>
                <li>Planning holidays</li>
                <li>Managing recurring monthly tasks, such as the month-end procedures of a finance function, or inventory stock counts</li>
            </ul>
            

            <p>
                The collaboration features built into Exenea will let you work on lists with others, or simply share read-only copies of lists with others. This could, for example, allow co-workers working on a project to use a list to manage that project. Their boss or their client could keep up to date on the progress of the project using read-only links, or even provide feedback within the list if allowed by the list owner.
            </p>

            <p>
                <a (click)="this.router.navigate(['examples']);">Examples of Exenea in action.</a>
            </p>

            <p class="news-post-header">
                What features will Exenea have?
            </p>

            <p>
                The first test release will contain all the core features including, but not limited to, the following:
            </p>

            <ul>
                <li>Adding as many columns as you'd like</li>
                <li>Changing column data types between text, dates, and various different kinds of status symbols</li>
                <li>Sharing lists with others, and control over exactly what changes (if any) other people can make</li>
                <li>Highlighting rows to signify importance of specific items</li>
                <li>Text-only rows, which can also be used as headers within lists</li>
                <li>Nested rows up to three levels deep. This means that within each list you have nested rows within each row, and each of those nested rows can have their own nested rows, and so forth.</li>
                <li>Multiple lists</li>
                <li>Grouping lists within folders</li>
                <li>List descriptions and statuses, to allow for a quick overview of a given list</li>
                <li>List downloading and sharing in both PDF and Excel formats</li>
                <li>Filtering and sorting list data</li>
                <li>Collaboration on lists</li>
            </ul>

            <p>
                Features that are in development and will be available in the future:
            </p>

            <ul>
                <li>Organisation support</li>
                <li>Prefabricated and custom list templates</li>
                <li>List comments</li>
                <li>Sharing lists with others (including individuals not registered on Exenea) for feedback on list items in a specified column</li>
                <li>Importing from Excel</li>
                <li>Automation</li>
                <li>Reminders</li>
                <li>Smart lists</li>
                <li>List subscription option for regular email updates</li>
                <li>Archive and recovery of lists</li>
                <li>And a whole lot more!</li>
            </ul>


            <p class="news-post-header">
                When will Exenea be available?
            </p>

            <p>
                Exenea is currently under development, and an alpha test version will soon be available for public testing. 
            </p>

            <p>
                If you are interesting in participating in testing, you can <a (click)="this.router.navigate(['home']);">sign up for the newsletter</a> or follow Exenea on social media.
            </p>

            <hr>
        </div>
    </div>
   
</div>