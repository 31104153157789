<h1 mat-dialog-title>Select date</h1>
<div mat-dialog-content>
    <div *ngIf="data.showDateSelection">
        Date: 
        <mat-form-field>
            <mat-label>Day</mat-label>
            <mat-select [(ngModel)]="data.day" ngDefaultControl name="daySelection">
                <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Month</mat-label>
            <mat-select [(ngModel)]="data.month" ngDefaultControl name="monthSelection">
                <mat-option *ngFor="let month of months; let i = index" [value]="i">{{month}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Year</mat-label>
            <mat-select [(ngModel)]="data.year" ngDefaultControl name="yearSelection">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="data.showTimeSelection">
        Time: 
        <mat-form-field>
            <mat-label>Hours</mat-label>
            <mat-select [(ngModel)]="data.hours" ngDefaultControl name="hourSelection">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Minutes</mat-label>
            <mat-select [(ngModel)]="data.minutes"ngDefaultControl name="minuteSelection">
                <mat-option *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        Test output: {{data.hours}}:{{data.minutes}} - {{data.day}}/{{data.month}}/{{data.year}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data.updatedValuesToDate()" cdkFocusInitial>Save</button>
    <button mat-button mat-dialog-close>Cancel</button>
</div>