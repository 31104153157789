
<div class="app-container" [style.background]="this.todoDBService.constants_colours.PageBackground">
    <!-- <mat-toolbar class="header-logo-toolbar" color="accent">    LOGO
        <span class="header-logo">
            TODO 0.3
        </span>
    </mat-toolbar>-->
    
    <mat-toolbar class="header-menu-toolbar"  color="primary">   <!-- HEADER --> 
        
        <button mat-icon-button class="header-menu-button" (click)="drawer.toggle()"
        *ngIf="this.todoDBService.CurrentUser">
            <mat-icon>menu</mat-icon>
        </button>
        <span *ngIf="this.todoDBService.CurrentUser" class="header-logo logo-font-style" (click)="this.router.navigate(['list']);">
            EXENEA
        </span>
        <span *ngIf="!this.todoDBService.CurrentUser" class="header-logo logo-font-style" (click)="this.router.navigate(['home']);">
            EXENEA
        </span>

        <!--
        <span>
            <button mat-button [matMenuTriggerFor]="mainMenu">Menu</button>
            <mat-menu #mainMenu="matMenu">
                <button mat-menu-item (click)="this.router.navigate(['home']);">Home</button>
                <button mat-menu-item (click)="this.router.navigate(['list']);">Lists</button>
                <button mat-menu-item (click)="this.router.navigate(['news']);">News</button>
                <button mat-menu-item [matMenuTriggerFor]="exeneaMenu">EXENEA</button>
                <button mat-menu-item (click)="this.router.navigate(['contact']);">Contact</button>
                <button mat-menu-item (click)="this.router.navigate(['admin']);">* Admin *</button>
            </mat-menu>
            <mat-menu #exeneaMenu="matMenu" >
                <button mat-menu-item (click)="this.router.navigate(['about']);">About</button>
                <button mat-menu-item (click)="this.router.navigate(['examples']);">Examples</button>
                <button mat-menu-item (click)="this.router.navigate(['templates']);">Templates</button>
            </mat-menu>
        </span>
        -->

        <!--<button mat-menu-item (click)="this.router.navigate(['home']);">Home</button>
        <button mat-menu-item (click)="this.router.navigate(['list']);">Lists</button>9-->
        <button mat-stroked-button  [style.borderColor]="this.todoDBService.constants_colours.PageBackground" (click)="this.router.navigate(['about']);">About</button>
        <button mat-stroked-button  [style.borderColor]="this.todoDBService.constants_colours.PageBackground" (click)="this.router.navigate(['news']);">News</button>
        <button mat-stroked-button  [style.borderColor]="this.todoDBService.constants_colours.PageBackground" (click)="this.router.navigate(['contact']);">Contact</button>
        <button mat-stroked-button  [style.borderColor]="this.todoDBService.constants_colours.PageBackground" (click)="this.router.navigate(['examples']);">Examples</button>

        <span class="menu-spacer"></span>
        

        <span *ngIf="this.todoDBService.currentList && this.todoDBService.listPermissions.canView">
            <span class="header-item-padding-right">
                <!-- <app-list-column-options [list]="this.todoDBService.currentList" [listPermissions]="this.todoDBService.listPermissions"></app-list-column-options> -->
                <button mat-stroked-button (click)="columnSortingDialog()" [disabled]="this.todoDBService.listPermissions.readOnly"
                [style.borderColor]="this.todoDBService.constants_colours.PageBackground">
                    <mat-icon>view_column</mat-icon> Columns
                </button> 
            </span> 
            <span>
                <button mat-stroked-button style="header-button" (click)="this.todoDBService.currentList.addNewRow(null, true, null);" [disabled]="this.todoDBService.listPermissions.readOnly"
                [style.borderColor]="this.todoDBService.constants_colours.PageBackground">
                    <mat-icon>playlist_add</mat-icon>
                    New row
                </button>
            </span>
                
            <!-- Share list, export, etc -->
        </span>

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu" style="menu">
            <ng-container *ngIf="this.todoDBService.CurrentUser">
                <span mat-menu-item disabled class="user-menu-item-text">Logged in as {{this.todoDBService.CurrentUser.name}}</span>
                <button mat-menu-item (click)="drawer.close(); this.router.navigate(['user/logout']);">
                    Logout
                </button>
            </ng-container>
            <ng-container *ngIf="!this.todoDBService.CurrentUser">
                <span mat-menu-item disabled class="user-menu-item-text">
                    You are not logged in
                </span>
            </ng-container>
        </mat-menu>
        
    </mat-toolbar>

    <!--<mat-progress-bar mode="indeterminate" id="progressBar" #progressBar
    class="progress-bar"></mat-progress-bar>-->
    <div class="progress-bar-container" >
        <mat-progress-bar mode="{{this.todoDBService.progressBarMode}}" 
        value="{{this.todoDBService.progressBarValue}}" id="progressBar" #progressBar 
        class="progress-bar" [class.progress-bar-loading]="this.todoDBService.displayProgressBar"></mat-progress-bar>
    </div>
    
    <mat-drawer-container class="sidenav-container" [hasBackdrop]="false" [style.background]="this.todoDBService.constants_colours.PageBackground"
    color="primary">
    
        <mat-drawer #drawer [mode]="'side'" class="sidenav" [style.background]="this.todoDBService.constants_colours.PageBackground">
            <div *ngIf="this.todoDBService.CurrentUser" class="sidenav-drawer-content">
                <app-list-selection [currentList]="this.todoDBService.currentList" [listPermissions]="this.todoDBService.listPermissions"></app-list-selection>
            </div>
        </mat-drawer>

        <mat-drawer-content class="sidenav-container-content">
              
            <router-outlet></router-outlet>

            <!--<app-user-login></app-user-login>-->
            
            <!-- <app-list-container></app-list-container> -->
            

            <div style="display: none">     <!-- This is needed because for some reason everything breaks if removed-->
                <mat-form-field>
                    <textarea matInput></textarea>
                </mat-form-field>
            </div> 
        
        </mat-drawer-content>

    </mat-drawer-container>

    


    <mat-toolbar class="footer-toolbar" color="primary">    <!-- FOOTER --> 
        <span>
            <span class="footer-text margin-right-10">
                <a href="mailto:hello@exenea.com?subject=Hello Exenea!">hello@exenea.com</a> 
            </span>
            <!-- span class="social-icon-container">
                <a href="https://www.facebook.com/Exenea-103712451445973"><img class="social-icon margin-right-10" src="assets/facebook.png"/></a>
            </span -->
            <span class="social-icon-container">
                <a href="https://twitter.com/JoinExenea"><img class="social-icon margin-right-10" src="assets/twitter.png"/></a>
            </span>
            <span class="global-warning-text">
                THIS WEBSITE IS STILL IN AN ALPHA TESTING PHASE - <a (click)="this.router.navigate(['about']);">MORE INFORMATION</a>
            </span>
            <!-- Contact details, social media, and other NB footer items -->
        </span> 
        <span class="menu-spacer"></span>
    </mat-toolbar>

</div>
