// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  firebase: {
    apiKey: "AIzaSyDPpiTuq7CqqIJ7NuY-qfkgtgkPHFRL-Jo",
    authDomain: "kiffbru-todo.firebaseapp.com",
    databaseURL: "https://kiffbru-todo.firebaseio.com",
    projectId: "kiffbru-todo",
    storageBucket: "kiffbru-todo.appspot.com",
    messagingSenderId: "201102893446",
    appId: "1:201102893446:web:fc261bfd63792df0a8cc07",
    measurementId: "G-5L4ZMVPY52"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
