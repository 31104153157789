<div #listBlackoutElement class="list-blackout" (click)="clickBlackout(listBlackoutElement)"></div>

<mat-card id="list-container" class="list-container" *ngIf="this.todoDBService.CurrentUser" [style.background]="this.todoDBService.constants_colours.ControlBackground">
    <ng-container >
        <div class="list-header">LISTS</div>
        <hr>

        <div class="list-container-sub" cdkDropListGroup>
            <div class="folder-container" *ngFor="let folder of this.todoDBService.CurrentUser.getListFolders()" [cdkDropListData]="folder" 
            cdkDropList > <!--(cdkDropListDropped)="drop($event)"-->
                <div cdkDrag cdkDragDisabled="true" *ngIf="!folder.deleted">
                    <mat-icon>folder</mat-icon>
                    <input 
                    class="list-folder-name-input" #editFolderElement matInput readonly
                    placeholder="..." [(ngModel)]="folder.title"
                    (dblclick)="editCell(listBlackoutElement, editFolderElement)"
                    (blur)="folder.updateServer(false)"
                    (keydown.enter)="clickBlackout(listBlackoutElement); folder.updateServer(false)" />
                    <mat-icon class="folder-delete" color="primary" [inline]="true" (click)="deleteFolder(folder)">delete</mat-icon>
                </div>

                <div>
                    <!--<div *ngFor="let list of this.todoService.getListDataByFolder(folder)" >-->
                    <div *ngFor="let list of folder.getLists()" >
                        <div *ngIf="!list.deleted && list.enabled" class="list-item" 
                        [ngClass]="{'list-item-selected': isSelected(list)}"
                        (click)="selectList(list)" 
                        cdkDrag cdkDragLockAxis="y" cdkDragBoundary=".list-container-sub"
                        (cdkDragDropped)="drop($event)" [cdkDragDisabled]="this.currentCellEdit">
                            <input 
                            class="list-name-input" #editCellElement matInput readonly
                            placeholder="..." [(ngModel)]="list.title" 
                            (dblclick)="editCell(listBlackoutElement, editCellElement)"
                            (blur)="list.updateServer(false)"
                            (keydown.enter)="clickBlackout(listBlackoutElement); list.updateServer(false)" />
                            <!--<mat-icon class="list-delete" color="primary" [inline]="true" (click)="deleteList(list)">delete</mat-icon>-->
                            <mat-icon class="list-more" color="primary" [inline]="true" (click)="openListMenu(list)">more_vert</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div  class="folder-container" [cdkDropListData]="null" 
            cdkDropList > <!-- (cdkDropListDropped)="drop($event)" -->
                <div cdkDrag cdkDragDisabled="true">
                    <mat-icon>folder</mat-icon> <span class="list-folder-name-input">Unallocated</span>
                </div>
                <div>
                    <div *ngFor="let list of this.todoDBService.CurrentUser.getUnallocatedLists()">
                        <div *ngIf="!list.deleted && list.enabled" class="list-item" 
                        [ngClass]="{'list-item-selected': isSelected(list)}" 
                        (click)="selectList(list)" 
                        cdkDrag cdkDragLockAxis="y" cdkDragBoundary=".list-container-sub"
                        (cdkDragDropped)="drop($event)" [cdkDragDisabled]="this.currentCellEdit">
                            <input 
                            class="list-name-input" #editCellElement matInput readonly
                            placeholder="..." [(ngModel)]="list.title" 
                            (dblclick)="editCell(listBlackoutElement, editCellElement)"
                            (blur)="list.updateServer(false)"
                            (keydown.enter)="clickBlackout(listBlackoutElement); list.updateServer(false)" />
                            <!--<mat-icon class="list-delete" color="primary" [inline]="true" (click)="deleteList(list)">delete</mat-icon>-->
                            <mat-icon class="list-more" color="primary" [inline]="true" (click)="openListMenu(list)">more_vert</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="folder-container" *ngIf="this.currentList && this.todoDBService.CurrentUser"> 
            <div *ngIf="this.currentList.userId!=this.todoDBService.CurrentUser.id && this.listPermissions.canView">
                <div>
                    <mat-icon>folder</mat-icon> <span class="list-folder-name-input">Shared</span>
                </div>
                <div class="list-item list-item-selected" >
                    {{this.currentList.title}}
                </div>
            </div>
        </div>
        
        <br/>
        <mat-icon class="add-list-icon" (click)="newList()">add_circle_outline</mat-icon>
        <mat-icon class="add-list-folder-icon" (click)="newListFolder()">create_new_folder_outlined</mat-icon>

        <hr/>
        <div class="list-header">SHARED LISTS</div>
        <hr/>
        
        <!-- 
        <br/>
        <br/>
        <hr/>
        <div class="list-header">STATUS ETC (TEMP LOCATION)</div>
        <hr/>
        
        <ng-container *ngIf="this.currentList && this.listPermissions.canView">

            <span [ngSwitch]="this.currentList.listStatus">
                <mat-icon class="check-base check-yes" *ngSwitchCase="'yes'" (dblclick)="updateListStatus(this.currentList, 'blank')">check_circle_outline</mat-icon>
                <mat-icon class="check-base check-warning" *ngSwitchCase="'warning'" (dblclick)="updateListStatus(this.currentList, 'yes')">error_outline</mat-icon>
                <mat-icon class="check-base check-inprogress" *ngSwitchCase="'in progress'" (dblclick)="updateListStatus(this.currentList, 'warning')">flip_camera_android</mat-icon>
                <mat-icon class="check-base check-no" *ngSwitchCase="'no'" (dblclick)="updateListStatus(this.currentList, 'in progress')">highlight_off</mat-icon>
                <mat-icon class="check-base" *ngSwitchDefault (dblclick)="updateListStatus(this.currentList, 'no')">radio_button_unchecked</mat-icon>
            </span>

            <textarea class="list-description" matInput
            placeholder="Description..."
            [(ngModel)]="this.currentList.listDescription" rows=3 
            [disabled]="this.listPermissions.readOnly"
            (blur)="this.currentList.updateServer(false)"></textarea>

            <mat-checkbox color="primary" [checked]="this.currentList.isPublic" 
            [disabled]="this.listPermissions.readOnly"
            (change)="this.currentList.isPublic = !this.currentList.isPublic; this.currentList.updateServer(false)">
                Public list?
            </mat-checkbox>

        </ng-container>-->
    </ng-container>
</mat-card>