import { Injectable, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, NgZone } from '@angular/core';
import { AngularFirestore, DocumentData } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFireAuth } from 'angularfire2/auth';
import { Constants } from './constants';
import { TriggerDataRefreshEvent, database, AFSDB } from './constants';
import { Observable } from 'rxjs';
import { FirebaseDatabase, ɵZoneScheduler } from 'angularfire2';
import * as firebase from 'firebase';
import { FnParam } from '@angular/compiler/src/output/output_ast';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
//import { ListPermissions } from './list-container/list-container.component';
import { User } from './elements/user';
import { List, ListPermissions } from './elements/list';


/*
export var database: FirebaseDatabase;
export var AFSDB: AngularFirestore;
export var TriggerDataRefreshEvent: EventEmitter<string>;*/


@Injectable({
  providedIn: 'root'
})

export class TodoDBService implements OnInit {
  

  public CurrentUser: User = null;
  public constants_colours;

  public currentList: List;
  public listPermissions: ListPermissions;

  public displayProgressBar: boolean = false;
  public progressBarMode: string = "indeterminate";
  public progressBarValue: number = 0;

  //public UserLoadedEvent: EventEmitter<User>;
  //public UserCreatedEvent: EventEmitter<User>;
  //public static TriggerDataRefreshEvent: EventEmitter<string>;

  constructor(public db: AngularFireStorage, public afAuth: AngularFireAuth, public afsdb: AngularFirestore, public zone: NgZone) {
   // database = firebase.database();
    //AFSDB = afsdb;
    //this.log("Initiated for version "+Constants.VERSION);
    //this.log("DB lists: "+Constants.DB_LISTS);
    //this.UserLoadedEvent = new EventEmitter<User>();
    //this.UserCreatedEvent = new EventEmitter<User>();
    //TriggerDataRefreshEvent = new EventEmitter();

    /*const loadUserData = new Observable(obs => {
      
    });*/
    this.constants_colours = Constants.Colours;

    this.afAuth.auth.onAuthStateChanged(afuser => {
      if (afuser) {
        this.log("Logged in (auth only) user id "+afuser.uid+" and email "+afuser.email);

        User.GetUser(afuser.uid).then( (ref: User) => {
          this.CurrentUser = ref;
          this.log("Loaded user id "+this.CurrentUser.id+" and email "+this.CurrentUser.email);
          
        }).finally(() => {TriggerDataRefreshEvent.emit("user loaded");})
        .catch(err => {});
      }
      else {
        this.CurrentUser = null;
        this.log("Not logged in");
      }
    });

    /*const userCreatedSubscription = this.UserCreatedEvent.subscribe((user: User) => {
      if (user) {
        this.updateUserOnServer(user);
        userCreatedSubscription.unsubscribe();
      }
    });*/
  }

  log(message: string): void {
    console.log("> TodoDBService: " + message)
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    TriggerDataRefreshEvent.unsubscribe();
    this.CurrentUser = null;
    this.destroy();
  }

  destroy() {
    this.CurrentUser.destroy();
    this.CurrentUser = null;

    this.log("Destroyed");
    //TODO: I'm not 100% certain if this is actually called when TodoService is destroyed - To test
  }

  /*** USERS ***/

  async loginUser(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then(ref => {
        this.log("Logged in! " + ref.user.email);
        TriggerDataRefreshEvent.emit("user");
        //this.CurrentUser = new User("-1", "Bob", ref.user.email);
        resolve(true);
      }).catch(err => {
        this.log("Error logging in: " +err.code+" - "+err.message);
        reject(err);
      });
    });
  }

  async currentUserLogout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut().then(ref => {
        this.CurrentUser = null;
        TriggerDataRefreshEvent.emit("user logout");
        resolve(true);
      }).catch(err => {
        this.log("Error signing out: "+err);
        reject(err);
      });
    });
  }
  
  
  async createUserAccount(name: string, email: string, password: string):Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(ref => {
        this.log("User created! "+ref.user.email);
        //this.UserCreatedEvent.emit(new User(ref.user.uid, name, ref.user.email, Date.now())); //Ued an event because for some reason directly calling updateUserOnServer doesn't work (DB not updated)
        this.CurrentUser = new User(ref.user.uid, name, ref.user.email, Date.now(), "0");
        TriggerDataRefreshEvent.emit("user");
        this.CurrentUser.updateServer();
        resolve(true);
      })
      .catch(err => {
        this.log("Error creating user: " +err.code+" - "+err.message);
        reject(err);
      })
    });
  }


  
}













