import { Component, OnInit, ChangeDetectorRef, ApplicationRef, ChangeDetectionStrategy, NgZone, Input, Inject } from '@angular/core';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatButton } from '@angular/material/button';
import { KeyValue } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListColumnOptionsDialogComponent } from '../list-column-options-dialog/list-column-options-dialog.component';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
//import { ListPermissions } from '../list-container/list-container.component';
import { List, ListPermissions } from '../elements/list';
import { Column } from '../elements/column';

@Component({
  selector: 'app-list-column-options',
  templateUrl: './list-column-options.component.html',
  styleUrls: ['./list-column-options.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListColumnOptionsComponent implements OnInit {

  //@Input() list: List;
  //@Input() listPermissions: ListPermissions;

  reorderColumnsDisplay = "none";
  columnOptionsDisplay = "none";
  blackoutElementDisplay = "none";
  columnItemEditing: Column = null;
  deleteConfirm = false;

  /*columnTitleEditing = "";
  columnDataTypeEditing = "";*/

  constructor(public dialogRef: MatDialogRef<ListColumnOptionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog, public todoDBService: TodoDBService,
    public changeDetectorRef: ChangeDetectorRef, public zone: NgZone) { 
      TriggerDataRefreshEvent.subscribe(ref => {
        //this.changeDetectorRef.detectChanges(); //Update template for changes
      });
    }

  ngOnInit(): void {
    TriggerDataRefreshEvent.emit("columns");
  }

  ngOnDestroy(): void {
    this.columnItemEditing = null;
  }

  reorderColumnsToggle(): void {
    this.reorderColumnsDisplay = (this.reorderColumnsDisplay == "none") ? "" : "none";
    if (this.reorderColumnsDisplay == "none") //Update server for any changes when closed
      this.data.list.updateServer_ColumnsOnly();
      //this.todoDBService.CurrentUser.getSelectedList().updateServer_ColumnsOnly();
    TriggerDataRefreshEvent.emit("columns");
  }

   /* Move drag and dropped columns and rows */
  dropColumn(event: CdkDragDrop<string[]>) {
    //this.todoService.moveColumn(event.previousIndex, event.currentIndex);
    //this.todoDBService.CurrentUser.getSelectedList().moveColumn(event.previousIndex, event.currentIndex);
    this.data.list.moveColumn(event.previousIndex, event.currentIndex);
  }

  /* Add a new column at the end of the table */
  addColumn() {
    //this.todoService.addNewColumnAtEnd();
    //this.todoDBService.CurrentUser.getSelectedList().addNewColumnAtEnd();
    this.data.list.addNewColumnAtEnd();
  }

  columnOptionsDialog(column: Column): void {
    this.zone.run(() => {
      this.dialog.open(ListColumnOptionsDialogComponent, {data: column});
    });
  }

     /* keyvalue pipe inputs for DataType values */ 
  // Order by ascending property value
  valueAscOrder = (a, b): number => {
    //return a.value.localeCompare(b.value); 
    
    return a.value.position - b.value.position;
    //return -1; 
  }

  //trackByItems(index: number, item: Item): number { return item.id; }

  trackByPosition(index: number, item): number { return item.position; }
}

export interface DialogData {
  list: List,
  listPermissions: ListPermissions
}