import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ApplicationRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TodoDBService } from '../todoDB.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public changeDetectorRef: ChangeDetectorRef, public applicationRef: ApplicationRef) { 
    //this.changeDetectorRef.detectChanges(); //Update template for changes
  }

  ngOnInit(): void {

  }

}

export interface DialogData {
  element: string;
  title: string;
}