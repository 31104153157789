import { Injectable, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { List } from './list';
import { ListFolder } from './listfolder';
import { TriggerDataRefreshEvent, database, AFSDB } from '../constants';
import { Constants } from '../constants';
import { Column } from './column';
import { Row } from './row';
import { Cell } from './cell';
import { Subscription } from 'rxjs';

 
export class User implements OnInit {
  id: string = "";
  name: string = "";
  email: string = "";
  dateJoined: number;
  selectedListId: string;
  /* DON'T FORGET TO CHANGE SERVER UPDATE CODE FOR CHANGES HERE */ 

  UnallocatedLists: List[];
  ListFolders: ListFolder[];
  
  UserDataLoaded: boolean;
  UserListPropertiesLoaded: boolean;
  UserListFolderPropertiesLoaded: boolean;
  UserListDataExpected: boolean;
  UserLoadComplete: boolean;
  private dataRefreshSubscription: Subscription;

  constructor (id, name, email, dateJoined, selectedListId) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.dateJoined = dateJoined;
    this.selectedListId = selectedListId;

    this.UnallocatedLists = [];
    this.ListFolders = [];
    this.UserDataLoaded = false;
    this.UserListDataExpected = false;
    this.UserListPropertiesLoaded = false;
    this.UserListFolderPropertiesLoaded = false;
    this.UserLoadComplete = false;

    /*this.dataRefreshSubscription = TriggerDataRefreshEvent.subscribe(ref => {
      try {
        if (!this.UserPropertiesLoaded) {
          let inCompleteCount = 0;
          this.UnallocatedLists.forEach(list =>  {if (!list.ListLoadCompleted) inCompleteCount++ });
          this.ListFolders.forEach(folder => { if(!folder.ListFolderLoadComplete) inCompleteCount++ });

          if (inCompleteCount == 0) {
            this.UserPropertiesLoaded = true;
            User.Log("**User properties loaded");
          }
        }
        if (!this.UserLoadComplete) {
          if (this.UserDataLoaded && this.UserPropertiesLoaded) {
            this.UserLoadComplete = true;
            User.Log("**User load completed");
          }
          else if (this.UserDataLoaded && !this.UserListDataExpected && !this.UserPropertiesLoaded) {
            this.UserLoadComplete = true;
            User.Log("**User load completed");
          }
        }
      }
      catch {}
    });*/
  }

    ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy();
  }

  destroy() {
    this.UnallocatedLists.forEach(list => list.destroy());
    this.ListFolders.forEach(folder => folder.destroy());
    this.UnallocatedLists = null;
    this.ListFolders = null;
    this.dataRefreshSubscription.unsubscribe();
  }

  static Log(message: string): void {
    console.log("> User: " + message)
  }

  static async GetUser(uid: string): Promise<User> {
    return new Promise((resolve, reject) => {
      database.ref(Constants.DB_USERS+"/"+uid).once("value").then(snapshot => {
        let tempUser = new User(uid, snapshot.val().name, snapshot.val().email, snapshot.val().dateJoined, snapshot.val().selectedListId);
        User.Log("**User data loaded");

        tempUser.loadProperties().then(() => {
          //User.Log("**User load complete");
          tempUser.UserDataLoaded = true;
          tempUser.UserListDataExpected = true;
          TriggerDataRefreshEvent.emit("user");
          //TriggerDataRefreshEvent.emit("data loaded: user");
          resolve(tempUser);

        }).catch(() => { resolve(null) });

      }).catch(err => {
        reject("Error loading user");
      });
    });
  }
  
  async updateServer(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        User.Log("Updating user "+this.email);
        TriggerDataRefreshEvent.emit("user");
        if (this.id == "0" || this.id == "-1")
          this.id = AFSDB.createId();
    
    
        database.ref(Constants.DB_USERS+"/"+this.id).update({
          id: this.id,
          name: this.name,
          email: this.email,
          dateJoined: this.dateJoined,
          selectedListId: this.selectedListId,
        }).then(() => {
          resolve(true);
        }).catch(() => {resolve(false)});

      }
      catch (err) {reject(err)}
    });

  }

  async updateServer_ListAndFolderDataOnly(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        TriggerDataRefreshEvent.emit("user");

        let pos = 1;
        this.UnallocatedLists.sort(function(a, b) {return (a.position - b.position)}).forEach(list => {
          if (!list.deleted)
            null;//list.position = pos++;
          list.updateServer(false);
        });
        
        this.ListFolders.forEach(folder => folder.updateServer_ListDataOnly());

        TriggerDataRefreshEvent.emit("list");

        resolve(true);

      }
      catch(err) {reject(err)}
    });



  }

  async loadProperties(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        User.Log("Loading properties");

        this.UnallocatedLists = [];
        database.ref(Constants.DB_LISTS).orderByChild("userId").equalTo(this.id).once("value").then(snapshot => {
          snapshot.forEach(item => {
            if (item.val().folderId=="-1" || item.val().folderId=="0") {
              User.Log("Found list "+item.val().title);
              //let list = List.GetListFromValues(item.val(), false, list => this.listCallback(list));
              List.GetListFromValues(item.val(), false).then(list => { 
                if (list) this.UnallocatedLists.push(list);
                TriggerDataRefreshEvent.emit("user");
              });
            }
          });
        }).then(() => {
          
          User.Log("Unallocated lists loading complete, loading folders");
          this.ListFolders = [];
          database.ref(Constants.DB_LIST_FOLDERS).orderByChild("userId").equalTo(this.id).once("value").then(snapshot => {
            snapshot.forEach(item => {
              User.Log("Found list folder "+item.val().title);
              let listFolder = ListFolder.GetListFolderFromValues(item.val(), false, () => this.listFolderCallback());
              if (listFolder) this.ListFolders.push(listFolder);
              TriggerDataRefreshEvent.emit("user");
            });
          }).then(() => {
            User.Log("Folder loading complete");
            //User.Log("**User properties loaded");
            
            TriggerDataRefreshEvent.emit("user");
            resolve(true);
          });
     
        }).catch(() => { resolve(false) });
          }
      catch (err) {reject(err)}
    });
  }

  listCallback(list: List) {
    //User.Log("List callback - Number of rows: "+list.Rows.length);
    if (!this.UserListPropertiesLoaded) {
      let inCompleteCount = 0;
      this.UnallocatedLists.forEach(list =>  {if (!list.ListLoadCompleted) inCompleteCount++ });
      //this.ListFolders.forEach(folder => { if(!folder.ListFolderLoadComplete) inCompleteCount++ });

      if (inCompleteCount == 0) {
        this.UserListPropertiesLoaded = true;
        //User.Log("**User list properties loaded.");
      }
    }
    if (!this.UserLoadComplete && this.UserListPropertiesLoaded && this.UserListFolderPropertiesLoaded) {
      this.UserLoadComplete = true;
      User.Log("**User load complete");
      TriggerDataRefreshEvent.emit("user data and properties load complete");
    }
  }

  listFolderCallback() {
    //User.Log("List folder callback");
    if (!this.UserListFolderPropertiesLoaded) {
      let inCompleteCount = 0;
      //this.UnallocatedLists.forEach(list =>  {if (!list.ListLoadCompleted) inCompleteCount++ });
      this.ListFolders.forEach(folder => { if(!folder.ListFolderLoadComplete) inCompleteCount++ });

      //User.Log("List folders incomplete count: "+inCompleteCount);

      if (inCompleteCount == 0) {
        this.UserListFolderPropertiesLoaded = true;
        //User.Log("**User list folder properties loaded");
      }
    }
    if (!this.UserLoadComplete && this.UserListPropertiesLoaded && this.UserListFolderPropertiesLoaded) {
      this.UserLoadComplete = true;
      User.Log("**User load complete");
      TriggerDataRefreshEvent.emit("user data and properties load complete");
    }
  }

  /*** LISTS ***/

  addNewList(): List{
    let newList = new List(AFSDB.createId(), this.getNewListPosition(), "New list", true, false, this.id, true, true, "-1", "", "", false);
    this.UnallocatedLists.push(newList);
    newList.updateServer(true);

    return newList;
  }

  getNewListPosition(): number {
    let pos: number = 0;
    this.getUnallocatedLists().forEach(list => { if (list.position>pos) pos = list.position });
    pos++;
    return (pos);
  }

  setSelectedList(list: List) {
    this.selectedListId = list.id;
    this.updateServer();
  }

  moveList(previousIndex: number, currentIndex: number, previousFolderId: string, newFolderId: string): void {
    if (currentIndex==-1) currentIndex = 0;
    let previousPosition = previousIndex+1;
    let newPosition = currentIndex+1;
    let originalItem = this.getListDataByFolderId(previousFolderId)[previousIndex];
    User.Log("Moving list from "+previousPosition+" to "+newPosition);

    if (previousFolderId == newFolderId) {
      User.Log("Moving in same folder");
      this.getListDataByFolderId(newFolderId).forEach( list => {
          //User.Log("Checking list '"+list.title+"' - Position = "+list.position);
          if (newPosition > previousPosition && list.position > previousPosition && list.position <= newPosition) { list.position--; User.Log("List position--") }
          if (newPosition < previousPosition && list.position <= previousPosition && list.position >= newPosition) { list.position++; User.Log("List position++") }
      });

      //User.Log("Moved list details: "+this.getListDataByFolderId(newFolderId)[previousIndex].title+" from "+this.getListDataByFolderId(newFolderId)[previousIndex].position+" to "+newPosition);
      //this.getListDataByFolderId(newFolderId)[previousIndex].position = newPosition;
      //this.getListDataByFolderId(newFolderId).forEach(list => User.Log(list.title+" - "+list.position));

      //User.Log("Moved list details: "+originalItem.title+" from "+originalItem.position+" to "+newPosition);
      originalItem.position = newPosition;
      this.getListDataByFolderId(newFolderId).forEach(list => User.Log(list.title+" - "+list.position));
    }
    else {
      User.Log("Moving to different folder");
      let newFolderLists =  this.getListDataByFolderId(newFolderId);
      let previousFolderLists = this.getListDataByFolderId(previousFolderId);

      newFolderLists.forEach( list => {
        if (list.position >= newPosition) list.position++;
      });

      previousFolderLists.forEach (list => {
        if (list.position >= previousPosition) list.position--;
      });

      previousFolderLists[previousIndex].position = newPosition;
      previousFolderLists[previousIndex].folderId = newFolderId;

      //newFolderLists.push(this.getListDataByFolderId(previousFolderId)[previousIndex]);
      //newFolderLists.push(previousFolderLists[previousIndex]);
      //User.Log("previousFolderId = '"+previousFolderId+"'");
      User.Log("previousFolderId = '"+previousFolderId+"'");
      if (previousFolderId=="-1") {
        let newPrevIndex = 0;
        this.UnallocatedLists.forEach(list => { 
          if (list.id == originalItem.id)
            this.UnallocatedLists.splice(newPrevIndex, 1);
          newPrevIndex++;
        });
        //this.UnallocatedLists.splice(previousIndex, 1);
      }
      else {
        let newPrevIndex = 0;
        this.getListFolderById(previousFolderId).Lists.forEach(list => { 
          if (list.id == originalItem.id)
            this.getListFolderById(previousFolderId).Lists.splice(newPrevIndex, 1);
          newPrevIndex++;
        });
        //this.getListFolderById(previousFolderId).Lists.splice(previousIndex,1);
      }

      if (newFolderId=="-1")
        this.UnallocatedLists.push(previousFolderLists[previousIndex]);
      else 
        this.getListFolderById(newFolderId).Lists.push(previousFolderLists[previousIndex]);
      
      
      
      //previousFolderLists.splice(previousIndex,1);
    }

    TriggerDataRefreshEvent.emit("list");

    this.updateServer_ListAndFolderDataOnly();
  }

  getListDataByFolderId(folderId): List[] {
    if (folderId=="-1" || folderId=="0")
      return this.getUnallocatedLists();
    
    let lists: List[] = [];
    this.ListFolders.forEach(folder => {
      if (folder.id == folderId)
        lists = folder.getLists();
    });
    
    return lists;
  }

  getSelectedList(): List {
    //User.Log("TEMPLATE FUNCTION CALL - getSelectedList");
    let listReturn: List = null;
    this.UnallocatedLists.forEach(list => {
      if (list.id == this.selectedListId)
        listReturn = list;
    });
    if (!listReturn) {
      this.ListFolders.forEach(folder => {
        folder.Lists.forEach(list => {
          if (list.id == this.selectedListId)
            listReturn = list;
        });
      });
    }

    return listReturn;
  } 

  getListById(id: string): List {
    let listReturn: List = null;
    listReturn = this.UnallocatedLists.filter(list => list.id == id)[0];
    if (!listReturn) {
      this.ListFolders.forEach(folder => {
        if (folder.Lists.filter(list => list.id == id)[0])
          listReturn = folder.Lists.filter(list => list.id == id)[0];
      });
    }

    return listReturn;
  }

  getNumberOfLists(): number {
    let num = 0;
    this.getUnallocatedLists().forEach(list => { if (list.enabled && !list.deleted) num++ });
    this.ListFolders.forEach(folder => {
      folder.Lists.forEach(list => { if (list.enabled && !list.deleted) num++ });
    });

    

    return num;
  }

  duplicateList(list: List) {
    let rowIdTracker = [];
    let colIdTracker = [];

    User.Log("Duplicating list '"+list.title+"' with "+list.Columns.length+" columns and "+list.Rows.length+" rows");

    let newList = new List(AFSDB.createId(), this.getNewListPosition(), list.title + " - Copy", list.enabled,list.deleted, this.id, list.showOptionsColumn, list.showRowNumberColumn, -1, list.listStatus, list.listDescription, false);
    newList.Columns = [];
    newList.Rows = [];
  
    list.Columns.forEach(col => {
      let newCol = new Column(AFSDB.createId(), col.position, col.title, col.width, col.enabled, col.deleted, col.dataType, newList.id);
      //this.columnData.push(newCol);
      newList.Columns.push(newCol);
      colIdTracker[col.id] = newCol.id;
      User.Log("Duplicating column - "+newCol.title);
    });
    
    //First create all rows, but parents will need to be updated
    //Keep running array of oldIds vs newIds. Then once all new rows created, go through and update parents using that array
    list.Rows.forEach(row => {
      let newRow = new Row(AFSDB.createId(), row.position, row.enabled, row.parent, row.expanded, row.deleted, newList.id, Date.now(), -1, row.isHighlighted, row.isTextRow, row.textRowData, row.textRowIsBold, row.textRowIsItalic);
      //this.rowData.push(newRow);      
      newList.Rows.push(newRow);
      rowIdTracker[row.id] = newRow.id;
      User.Log("Duplicating row - "+newRow.position);
    });

    newList.Rows.forEach(row => {
      if (rowIdTracker[row.parent])
        row.parent = rowIdTracker[row.parent];
    });

    /*
    list.getRows().forEach(row => {
      row.Cells.forEach(cell => {
        let newCell = new Cell(AFSDB.createId(), colIdTracker[cell.columnId], rowIdTracker[cell.rowId], cell.cellData, newList.id);
        //this.cellData.push(newCell);
        newList.Rows.forEach(newRow => {
          if (rowIdTracker[row.id] == newRow.id) {
            newRow.Cells.push(newCell);
            User.Log("Duplicating cell - "+newCell.toString());
          }
        });
      });
    });*/
    /* COPY CELLS */
    list.Rows.forEach(row => {
      list.Columns.forEach(col => {
        let oldCell = list.Cells[row.id][col.id];
        let newCell = new Cell(AFSDB.createId(), colIdTracker[oldCell.columnId], rowIdTracker[oldCell.rowId], oldCell.cellData, newList.id);
        newList.Cells[rowIdTracker[oldCell.rowId]][colIdTracker[oldCell.columnId]] = newCell;
        User.Log("Duplicating cell - "+newCell.toString());
      });
    });
    User.Log("List duplication complete - Updating server");

    /*this.getCellDataByList(list.id).forEach(cell => {
      let newCell = new CellItem(this.db.createId(), colIdTracker[cell.columnId], rowIdTracker[cell.rowId], cell.cellData, newList.id);
      this.cellData.push(newCell);
    });*/

    this.UnallocatedLists.push(newList);

    newList.updateServer(true);
  }

  getUnallocatedLists(): List[] {
    //User.Log("TEMPLATE FUNCTION CALL - getUnallocatedLists");
    return this.UnallocatedLists.filter(list => !list.deleted).sort(function(a, b) {return (a.position - b.position)});
  }

  


  /*** LIST FOLDERS ***/
  addNewListFolder(): void {
    let newListFolder = new ListFolder(AFSDB.createId(), "New Folder", false, this.id, true);
    this.ListFolders.push(newListFolder);
    newListFolder.updateServer(true);
  }

  getListFolders(): ListFolder[] {
    //User.Log("TEMPLATE FUNCTION CALL - getListFolders");
    return this.ListFolders.filter(folder => !folder.deleted).sort(function(a, b) {return (a.title.localeCompare(b.title))});
  }

  getListFolderById(id: string): ListFolder {
    User.Log("getListFolderById for id "+id);
    let list = this.getListFolders().filter(folder => folder.id == id)[0];
    if (list)
      return list;

    return null;
  }



}