<div class="login-container" class="page-base">
    <div class="page-header">User</div>
    
    <div *ngIf="this.todoDBService.CurrentUser"> <!-- If logged in -->
        Hello, {{this.todoDBService.CurrentUser.name}}.<!--<br/>
        <button mat-stroked-button color="primary" (click)="logout()">Log out</button>
        <br/>
        <br/>-->
    </div>

    <div *ngIf="!this.todoDBService.CurrentUser && loginOrCreate==''" class="page-align-center"> <!-- If not logged in -->
        You are not logged in. 
        <div class="margin-top-10">
            <button mat-stroked-button color="primary" class="margin-right-10" (click)="loginForm()">Log in</button>
            <button mat-stroked-button color="primary" (click)="createForm()">Create account</button>
        </div>
    </div>

    <div *ngIf="!this.todoDBService.CurrentUser && loginOrCreate=='loading user'" class="page-align-center"> <!-- Logging in -->
        Logging you in
    </div>

    <div *ngIf="!this.todoDBService.CurrentUser && loginOrCreate=='login'" class="page-align-center"> <!-- Login form -->
        <form> <!-- Need to change to Material controls and do error checking --> 
            <div>
                <mat-form-field>
                    <mat-label>Email address</mat-label>
                    <input matInput type="email"  required #email [formControl]="emailFormControl">
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address 
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email address is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" required #password [formControl]="requiredFormControl">
                    <mat-error *ngIf="requiredFormControl.hasError('required')">
                        Password is required
                    </mat-error>
                </mat-form-field>
                <div>
                    <button mat-stroked-button color="primary" class="margin-right-10" type="submit" (click)="login(email.value, password.value)">Log in</button>
                    <button mat-stroked-button color="primary" (click)="cancel()">Cancel</button>
                </div>    
            </div>
        </form>
    </div>

    <div *ngIf="!this.todoDBService.CurrentUser && loginOrCreate=='create'" class="page-align-center"> <!-- Register form - This is longer than other boxes, see if this can overflow over menu in aesthetically pleasing way? -->
        <form> <!-- Need to change to Material controls and do error checking --> 
            <div>
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput type="text" required #name [formControl]="requiredFormControl">
                    <mat-error *ngIf="requiredFormControl.hasError('required')">
                        Name is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Email address</mat-label>
                    <input matInput type="email" required #email [formControl]="emailFormControl">
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                        Please enter a valid email address 
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required')">
                        Email address is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput type="password" required #password [formControl]="requiredFormControl">
                    <mat-error *ngIf="requiredFormControl.hasError('required')">
                        Password is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>Repeat password</mat-label>
                    <input matInput type="password" required #password2 [formControl]="requiredFormControl">
                    <mat-error *ngIf="requiredFormControl.hasError('required')">
                        Password confirmation is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <button mat-stroked-button color="primary" class="margin-right-10"  type="submit" (click)="createAccount(name.value, email.value, password.value)">Create</button>
                <button mat-stroked-button color="primary" (click)="cancel()">Cancel</button>
            </div>
            <br/><br/>
        </form>
    </div>

</div>