import { Component, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { TodoDBService } from './todoDB.service';
import { Constants, TriggerDataRefreshEvent } from './constants';
import { Subscription } from 'rxjs/internal/Subscription';
import { Router, NavigationEnd } from '@angular/router';
import { ListColumnOptionsComponent } from './list-column-options/list-column-options.component';
import { MatDialog } from '@angular/material/dialog';
import { BoundAttribute } from '@angular/compiler/src/render3/r3_ast';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';
import { MatProgressBar } from '@angular/material/progress-bar';
//import { MatToolbar } from '@angular/material/toolbar';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})



export class AppComponent {
  title = 'todo01';
  @ViewChild('progressBar') progressBar: MatProgressBar;

  private dataRefreshSubscription: Subscription;
  //private pageLoadComplete: boolean;

  constructor(public todoDBService: TodoDBService, public constants: Constants, public dialog: MatDialog, 
    public changeDetectorRef: ChangeDetectorRef, public router: Router, public zone: NgZone) {  //private todoService
    //this.pageLoadComplete = false;

    //Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-175162979-1', 
          {
            'page_path': event.urlAfterRedirects
          });
      }
    });
   
    this.dataRefreshSubscription = TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes


      //if (ref=="progress started")
      //  this.progressBar._elementRef.nativeElement.style.visibility = "visible";

      //if (ref=="progress ended")
      //  this.progressBar._elementRef.nativeElement.style.visibility = "hidden";

      //if (ref=="user data and properties load complete") {
      //  TriggerDataRefreshEvent.emit("progress ended");
        //this.pageLoadComplete = true;
      //}


    });
  }

  ngOnInit(): void {
    //this.todoService.loadFirebaseData();
    //TriggerDataRefreshEvent.emit("progress started"); //Display progress bar on first load of page
  }

  ngOnDestroy(): void {
    this.todoDBService.destroy();
    this.dataRefreshSubscription.unsubscribe();
    TriggerDataRefreshEvent.unsubscribe();
  }

  columnSortingDialog(): void {
    this.zone.run(() => {
      this.dialog.open(ListColumnOptionsComponent, {data: { list: this.todoDBService.currentList, listPermissions: this.todoDBService.listPermissions }});
    });
  }
}
