import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, NgZone } from '@angular/core';
import { CdkDragDrop, CdkDropList, moveItemInArray, CdkDragMove, Point, DragRef } from '@angular/cdk/drag-drop';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
//import { ListPermissions } from '../list-container/list-container.component';
import { List, ListPermissions } from '../elements/list';
import { Column } from '../elements/column';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListHeaderComponent implements OnInit {

  @Input() list: List;
  @Input() listPermissions: ListPermissions;

  dragDistance = 0;

  constructor(public todoDBService: TodoDBService, public changeDetectorRef: ChangeDetectorRef, public zone: NgZone) { 
    TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes

    });


    
  }

  ngOnInit(): void {

  }

  dragMoved(event:  CdkDragMove<string[]>, column: Column) {
    this.list.totalColumnWidth -= column.width;

    column.width += event.distance.x - this.dragDistance;
    this.dragDistance = event.distance.x;

    if (column.width < 50) {
      column.width = 50;
      this.dragDistance = -event.distance.x;
    }

    this.list.totalColumnWidth += column.width;


    document.body.style.cursor = "col-resize";

    TriggerDataRefreshEvent.emit("column drag");

    /*
    column.width += event.distance.x - this.dragDistance;
    this.dragDistance = event.distance.x;

    if (column.width < 50) {
      column.width = 50;
      this.dragDistance = -event.distance.x;
    }

    document.body.style.cursor = "col-resize";   
    //this.todoDBService.CurrentUser.getSelectedList().updateColumnWidths();
    
    this.list.updateProperties_columnWidths();
    TriggerDataRefreshEvent.emit("column drag");*/
    
  }

  dragCompleted(event: CdkDragDrop<string[]>, column: Column) {
    this.dragDistance = 0;
    document.body.style.cursor = "default";
    //this.todoService.updateColumnOnServer(column, true);
    column.updateServer(false);
    this.list.updateProperties_columnWidths();
  }

    /* keyvalue pipe inputs for DataType values */ 
  // Order by ascending property value
  valueAscOrder = (a, b): number => {
    //return a.value.localeCompare(b.value); 
    
    return a.value.position - b.value.position;
    //return -1; 
  }

  expandClicked() {
    TriggerDataRefreshEvent.emit("list");
  }

  updateListStatus(listItem: List, newStatus: string): void {
    if (this.listPermissions.readOnly) return;

    this.zone.run(() => {
      listItem.listStatus = newStatus;
      listItem.updateServer(false);
    });
    TriggerDataRefreshEvent.emit("list");
  }
}
