<div cdkDropListGroup *ngIf="this.list && this.listPermissions.canView"> <!-- Wrapper around entire table -- class="{{getListContainerClass(nestingDepth)}}" -->
    <div #listBlackoutElement class="list-blackout" (click)="clickBlackout(listBlackoutElement)"></div>

    <div #contextMenu class="list-items-contextmenu"  (pointerleave)="closeContextMenu($event, contextMenu)">
        <mat-card #contextMenu class="list-items-contextmenu-card">
            <div>
                <button [disabled]="contextMenuDisableSubRow || this.listPermissions.readOnly" color="primary" mat-stroked-button (click)="contextMenuNewSubRow()">
                    <mat-icon>playlist_add</mat-icon>
                    <mat-icon>subdirectory_arrow_right</mat-icon>
                    New sub row
                </button>
                <button mat-stroked-button color="primary" (click)="contextMenuAddRowAbove()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>playlist_add</mat-icon>
                    <mat-icon>north</mat-icon>
                    New row above
                </button>
                <button mat-stroked-button color="primary" (click)="contextMenuAddRowBelow()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>playlist_add</mat-icon>
                    <mat-icon>south</mat-icon>
                    New row below
                </button>
                <button mat-stroked-button color="primary" (click)="contextMenuRemoveRow(contextMenu)" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>clear</mat-icon>
                    Delete row
                </button>
            </div>
            <div>
                <button mat-stroked-button color="primary" [disabled]="contextMenuDisableIndent" (click)="indentRow()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>read_more</mat-icon>
                    Indent
                </button>
                <button mat-stroked-button color="primary" (click)="openRowCard()">
                    <mat-icon>launch</mat-icon>
                    Open card
                </button>
                <button mat-stroked-button color="primary" (click)="toggleHighlight()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>star_outline</mat-icon>
                    <span *ngIf="this.contextMenuIsHighlighted">Unhighlight</span>
                    <span *ngIf="!this.contextMenuIsHighlighted">Highlight</span>
                </button>
                <button mat-stroked-button color="primary" (click)="toggleTextRow()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>power_input</mat-icon>
                    <span *ngIf="this.contextMenuIsTextRow">Convert to normal row</span>
                    <span *ngIf="!this.contextMenuIsTextRow">Covert to text-only row</span>
                </button>
            </div>
            <div *ngIf="this.contextMenuIsTextRow">
                <button mat-stroked-button color="primary" (click)="toggleTextRowBold()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>format_bold</mat-icon>
                    Bold
                </button>
                <button mat-stroked-button color="primary" (click)="toggleTextRowItalic()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>format_italic</mat-icon>
                    Italic
                </button>
            </div>
            <div>
                <button mat-stroked-button color="primary" (click)="duplicateRow()" [disabled]="this.listPermissions.readOnly">
                    <mat-icon>control_point_duplicate</mat-icon>
                    Duplicate row
                </button>
            </div>
        </mat-card>
    </div>

    <!-- <div class="rows-container" cdkDropList (cdkDropListDropped)="dropRow($event)"> -->
    <div class="rows-container-top" 
    cdkDropList (cdkDropListDropped)="dropRow($event)" [cdkDropListData]="'top'" [cdkDropListDisabled]="this.listPermissions.readOnly"
    #listContainer="cdkDropList" > 
        <!-- <div class="single-row-container" *ngFor="let row of todoService.getRowData()"  -->
        <div #rowContainer class="single-row-container" *ngFor="let row of this.list.Rows" 
        cdkDrag cdkDragLockAxis="y" cdkDragBoundary=".rows-container-top"
        (cdkDragMoved)="dragMoved($event)" (cdkDragStarted)="dragStarted($event)"> <!-- Cycle through each of the rows -->
            <!--Row ID: {{row.id}}, Row parent: {{row.parent}}, parentRowId: {{this.parentRowId}}, children: {{todoService.numberOfImmediateSubRows(row)}}-->
            <!--<div *ngIf="this.list.isRowExpanded(this.list.getRowById(row.parent)) && !row.deleted" class="{{this.getRowClass(row)}} " [style.width.px]="this.list.totalColumnWidth" > !-- Only show if this row is a child of immediate parent --> 
            <div *ngIf="row.IsRowParentExpanded && !row.deleted" class="{{row.RowClass}}" [class.list-row-highlight]="row.isHighlighted" [style.width.px]="this.list.totalColumnWidth" > <!-- Only show if this row is a child of immediate parent --> 
                <span class="list-row-options-move-container" class="list-row-options" cdkDragHandle > <!-- cdkDragBoundary=".rows-container" -->
                    <mat-icon class="list-row-options-move">drag_indicator</mat-icon>
                </span>
                <div *ngIf="this.list.showOptionsColumn" class="list-row-options">
                    <span class="list-row-options-menu-button" (click)="openContextMenu($event, contextMenu, row, rowContainer)">
                        <mat-icon>more_vert</mat-icon>
                    </span>
                </div>
                <div *ngIf="this.list.showRowNumberColumn" class="list-row-number">
                    <!-- {{this.todoService.getRowIndex(row)+1}} -->
                    {{row.position}}
                </div>
                <div class="list-row-toggles">
                    <span (click)="toggleSubRowsDisplay($event, row)" *ngIf="row.NumberOfSubRows>0">
                        <div [ngSwitch]="row.expanded">
                            <mat-icon *ngSwitchCase="true">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngSwitchCase="false">keyboard_arrow_right</mat-icon>
                        </div>
                    </span>
                </div>

                <app-list-row-content [listPermissions]="listPermissions" [list]="this.list" [row]="row" [listBlackoutElement]="listBlackoutElement" [blackoutClosedEventEmitter]="blackoutClosedEventEmitter"></app-list-row-content> 

                
            </div>
            <!--<app-list-items [parentRow]="row" [nestingDepth]="nestingDepth+1" *ngIf="row.parent == this.parentRowId && todoService.numberOfImmediateSubRows(row)>0 && row.expanded"></app-list-items> <!-- Nest this component only if the row has children -->
        </div>
    </div>  


</div>







