import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';

@Component({
  selector: 'app-date-selection-dialog',
  templateUrl: './date-selection-dialog.component.html',
  styleUrls: ['./date-selection-dialog.component.css']
})
export class DateSelectionDialogComponent implements OnInit {
  years: number[] = [];
  months: string[] = [];
  days: number[] = [];
  hours: number[] = [];
  minutes: number[] = [];

  //Implement select boxes for years etc, possibly with integrated search box. Will need to error check for invalid dates. Adjust days automatically depending on selected month
  //Auto select input date (need to change this on list items component), if not valid then auto select todays date

  constructor(@Inject(MAT_DIALOG_DATA) public data: DateSelectionData ) { 
    this.years = [];
    for (let i = 1900; i<=2100; i++)
      this.years.push(i);

    this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    this.days = [];
    for (let i=1; i<=31; i++)
      this.days.push(i);

    this.hours = [];
    for (let i = 0; i<=23; i++)
      this.hours.push(i);

    this.minutes = [];
    for (let i = 0; i<=60; i+=5)
      this.minutes.push(i);

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.years = null;
    this.months = null;
    this.days = null;
    this.hours = null;
    this.minutes = null;
  }
}

export class DateSelectionData {
  showDateSelection: boolean;
  showTimeSelection: boolean;
  dateTime: Date;

  minutes: number;
  hours: number;
  day: number;
  month: number;
  year: number;

  constructor (dateTime, showDateSelection, showTimeSelection) {
    this.dateTime = new Date(dateTime);
    this.showDateSelection = showDateSelection;
    this.showTimeSelection = showTimeSelection;

    if (!Date.parse(dateTime)) this.dateTime = new Date(Date.now());

    this.minutes = this.dateTime.getMinutes();
    this.hours = this.dateTime.getHours();
    this.day = this.dateTime.getDate();
    this.month = this.dateTime.getMonth();
    this.year = this.dateTime.getFullYear();

    this.minutes = 5*Math.round(this.minutes/5); //Round to nearest multiple of 5
  }

  updatedValuesToDate(): Date {
    return new Date(this.year, this.month, this.day, this.hours, this.minutes);
    
  }
}