import { Column } from './column';
import { Row } from './row';
import { TriggerDataRefreshEvent, database, AFSDB } from '../constants';
import { Constants } from '../constants';
import { Cell } from './cell';
import { Subscription } from 'rxjs';
import { ApplicationRef } from '@angular/core';

export class List {
  id: string;
  position: number;
  title: string;
  enabled: boolean;
  deleted: boolean;
  userId: string;
  showOptionsColumn: boolean;
  showRowNumberColumn: boolean;
  folderId: string;
  listStatus: string;
  listDescription: string;
  isPublic: boolean;
  /* DON'T FORGET TO CHANGE SERVER UPDATE CODE FOR CHANGES HERE */ 

  Columns: Column[];
  Rows: Row[];
  Cells: Cell[];
  ListDataLoaded: boolean;
  ListPropertiesLoaded: boolean;
  ListLoadCompleted: boolean;
  ListColumnsLoaded: boolean;
  ListRowsLoaded: boolean;
  ListCellsLoaded: boolean;

  totalColumnWidth: number;
  dataColumnsWidth: number;

  private dataRefreshSubscription: Subscription;

  constructor (id, position, title, enabled, deleted, userId, showOptionsColumn, showRowNumberColumn, folderId, listStatus, listDescription,isPublic) {
    this.id = id;
    this.position = position;
    this.title = title;
    this.enabled = enabled;
    this.deleted = deleted;
    this.userId = userId;
    this.showOptionsColumn = showOptionsColumn;
    this.showRowNumberColumn = showRowNumberColumn;
    this.folderId = folderId;
    this.listStatus = listStatus;
    this.listDescription = listDescription;
    this.isPublic = isPublic;

    this.ListDataLoaded = false;
    this.ListPropertiesLoaded = false;
    this.ListLoadCompleted = false;
    this.ListColumnsLoaded = false;
    this.ListRowsLoaded = false;
    this.ListCellsLoaded = false;

    this.Columns = [];
    this.Columns.push(new Column(AFSDB.createId(), 1, "New col", 100, true, false, Column.DataType.Text, this.id));
    this.Rows = [];
    this.Rows.push(new Row(AFSDB.createId(), 1, true, "0", true, false, this.id, Date.now(), "-1", false, false, "", false, false));
    this.Cells = [];
    this.Cells.push(new Cell(AFSDB.createId(), this.Columns[0].id, this.Rows[0].id, "X", this.id));

    this.totalColumnWidth = this.getTotalColumnWidth();
    this.dataColumnsWidth = this.getDataColumnsWidth();

    /*this.dataRefreshSubscription = TriggerDataRefreshEvent.subscribe(ref => {
      try {
        if (!this.ListPropertiesLoaded) {
          let inCompleteCount = 0;
          this.Columns.forEach(col =>  {if (!col.ColumnDataLoaded) inCompleteCount++ });
          this.Rows.forEach(row =>  {if (!row.CellDataLoaded) inCompleteCount++ });

          if (inCompleteCount == 0) {
            this.ListPropertiesLoaded = true;
            List.Log("**List properties loaded for "+this.id);
          }
        }
        if (!this.ListLoadCompleted) {
          if (this.ListDataLoaded && this.ListPropertiesLoaded)
            this.ListLoadCompleted = true;
            List.Log("**List load completed for "+this.id);
        }
      }
      catch {} 
    });*/
  }

  destroy() {
    this.Columns.forEach(col => col.destroy());
    this.Rows.forEach(row => row.destroy());
    this.Columns = null;
    this.Rows = null;
    this.Cells.forEach(cell => cell.destroy());
    this.Cells = null;
    this.dataRefreshSubscription.unsubscribe();
  }

  toString() {
    return this.id + " ("+this.title+")";
  }

  static Log(message: string): void {
    console.log("> List: " + message)
  }

  static async GetListAndPropertiesFromValues(list, columns, rows, cells, finalCallback, iterateCallback): Promise<List> {
    return new Promise((resolve, reject) => {
      try {
        requestAnimationFrame(() => {});
        let tempList = new List(list.id, list.position, list.title, list.enabled, list.deleted, list.userId, list.showOptionsColumn, list.showRowNumberColumn, list.folderId, list.listStatus, list.listDescription, list.isPublic);
        let tempColumns: Column[] = [];
        let tempRows: Row[] = [];
        let tempCells: Cell[] = [];
        
        tempList.loadColumns(tempColumns, columns, 0, finalCallback, iterateCallback);
        tempList.loadRows(tempRows, rows, 0, finalCallback, iterateCallback);
        tempList.loadCells(tempCells, cells, 0, finalCallback, iterateCallback); 

        /*
        columns.forEach(x => {
          requestAnimationFrame(() => {});
          let col = Column.GetColumnFromValues(x);
          if (col) tempList.Columns.push(col);
        });

        rows.forEach(x => {
          requestAnimationFrame(() => {});
          let row = Row.GetRowFromValues(x);
          if (row) tempList.Rows.push(row);
        });

        cells.forEach(x => {
          requestAnimationFrame(() => {});
          let cell = Cell.GetCellFromValues(x);
          if (cell) {
            if (!tempList.Cells[cell.rowId])
              tempList.Cells[cell.rowId] = [];

            tempList.Cells[cell.rowId][cell.columnId] = cell;
          }
        });*/

        TriggerDataRefreshEvent.emit("list");
        //tempList.Columns = tempList.Columns.sort(function(a, b) {return (a.position - b.position)});
        //tempList.Rows = tempList.Rows.sort(function(a, b) {return (a.position - b.position)});

        //List.Log("** "+tempList.Rows.length+" rows loaded in list "+tempList.id+" ("+tempList.title+")");
        //tempList.checkForNilCells();
        //tempList.updateProperties();

        //List.Log("**List properties loaded for list "+tempList.id);
        //tempList.ListPropertiesLoaded = true;
        //tempList.ListDataLoaded = true;
        //tempList.ListLoadCompleted = true; 
        if (iterateCallback)
          iterateCallback();
        resolve(tempList);
      }
      catch (err) { 
        List.Log("List and properties load error: "+err); 
        reject (err); }
    });
  }

  loadColumns(columns: Column[], columnsRaw, index: number, finalCallback, iterateCallback) {
    if (columnsRaw[index]) {
      let col = Column.GetColumnFromValues(columnsRaw[index]);
      if (col) columns.push(col);
      if (iterateCallback)
          iterateCallback();
    } 
    if (index<columnsRaw.length) {
      index++;
      
      requestAnimationFrame(() => this.loadColumns(columns, columnsRaw, index, finalCallback, iterateCallback));
    }
    else {
      List.Log("Columns loaded - Found "+columns.length+" columns");
      this.Columns = columns;
      this.ListColumnsLoaded = true;
      if (this.ListColumnsLoaded && this.ListRowsLoaded && this.ListCellsLoaded)
        this.listLoadComplete(finalCallback);
    }
  }

  loadRows(rows: Row[], rowsRaw, index: number, finalCallback, iterateCallback) {
    if (rowsRaw[index]) {
      let row = Row.GetRowFromValues(rowsRaw[index]);
      if (row) rows.push(row);
      if (iterateCallback)
          iterateCallback();
    } 
    if (index<rowsRaw.length) {
      index++;
      requestAnimationFrame(() => this.loadRows(rows, rowsRaw, index, finalCallback, iterateCallback));
    }
    else {
      List.Log("Rows loaded - Found "+rows.length+" rows");
      this.Rows = rows;
      this.ListRowsLoaded = true;
      if (this.ListColumnsLoaded && this.ListRowsLoaded && this.ListCellsLoaded)
        this.listLoadComplete(finalCallback);
    }
  }

  loadCells(cells: Cell[], cellsRaw, index: number, finalCallback, iterateCallback) {
    if (cellsRaw[index]) {
      let cell = Cell.GetCellFromValues(cellsRaw[index]);
      //if (cell) cells.push(cell);
      if (cell) {
        if (!cells[cell.rowId])
          cells[cell.rowId] = [];

        cells[cell.rowId][cell.columnId] = cell;
      }
      if (iterateCallback)
          iterateCallback();
      //List.Log("Loaded cell "+cell.id+". Index = "+index+", length="+cellsRaw.length);
    } 
    if (index<cellsRaw.length) {
      index++;
      requestAnimationFrame(() => this.loadCells(cells, cellsRaw, index, finalCallback, iterateCallback));
    }
    else {
      List.Log("Finished loading cells");
      this.Cells = cells;
      this.ListCellsLoaded = true;
      if (this.ListColumnsLoaded && this.ListRowsLoaded && this.ListCellsLoaded)
        this.listLoadComplete(finalCallback);
    }
  }

  private listLoadComplete(finalCallback) {
    
    this.Columns = this.Columns.sort(function(a, b) {return (a.position - b.position)});
    this.Rows = this.Rows.sort(function(a, b) {return (a.position - b.position)});

    List.Log("** "+this.Rows.length+" rows loaded in list "+this.id+" ("+this.title+")");
    this.checkForNilCells();
    this.updateProperties();

    List.Log("**List properties loaded for list "+this.id);
    this.ListPropertiesLoaded = true;
    this.ListDataLoaded = true;
    this.ListLoadCompleted = true; 
    TriggerDataRefreshEvent.emit("list");
    if (finalCallback)
      finalCallback(this);
  }

  static async GetListFromId(id: string, callback): Promise<List> {
    return new Promise((resolve, reject) => {
      try {
        database.ref(Constants.DB_LISTS).orderByChild("id").equalTo(id).once("value").then(snapshot => {
          snapshot.forEach(item => {
            if (item) {
              //let list = List.GetListFromValues(item.val(), true, callback);
              List.GetListFromValues(item.val(), true).then(list => { 
                if (list) {
                  TriggerDataRefreshEvent.emit("list");
                  resolve (list);
                }
                else { reject() }
              });
            }
            else { reject() }
          });
        })
      }
      catch (err) { reject(err) }
    });
  }

  static async GetListFromValues(values, loadProperties: boolean): Promise<List> {
  //static GetListFromValues(values, loadProperties: boolean, callback): List {
    return new Promise((resolve, reject) => {
      try {
        let tempList = new List(values.id, values.position, values.title, values.enabled, values.deleted, values.userId, values.showOptionsColumn, values.showRowNumberColumn, values.folderId, values.listStatus, values.listDescription, values.isPublic);
        if (loadProperties) {
            tempList.loadProperties().finally(() => {
              //callback(tempList);
              resolve(tempList);
              TriggerDataRefreshEvent.emit("list");
            });
        }

        tempList.ListDataLoaded = true;
        TriggerDataRefreshEvent.emit("list");
        List.Log("List loaded - "+tempList.title);

        if (!loadProperties) {
          tempList.ListLoadCompleted = true;
          //if (callback)
            //callback(tempList);
          resolve(tempList);
        }

        return tempList;

      }
      catch(err) {
        List.Log("Error loading list: " + err);
        reject("Error loading list: " + err);
        return null;
      }
    });
  }

  async loadProperties(): Promise<boolean> { 
  //loadProperties(callback) {
    //return new Promise((resolve, reject) => {
      /*try {
        
        this.Columns = [];
        database.ref(Constants.DB_COLUMNS).orderByChild("listItemId").equalTo(this.id).once("value").then(snapshot => {
          snapshot.forEach(item => {
            //List.Log("Found a column: "+item.val().title);
            let col = Column.GetColumnFromValues(item.val());
            if (col) this.Columns.push(col);
            /*Column.GetColumnFromValues(item.val()).then(col => {
              if (col)
                this.Columns.push(col as Column);
            });*
            TriggerDataRefreshEvent.emit("list");
          });
        }).then(() => {
          //TriggerDataRefreshEvent.emit("data loaded: list columns");

          this.Rows = [];
          database.ref(Constants.DB_ROWS).orderByChild("listItemId").equalTo(this.id).once("value").then(snapshot => {
            snapshot.forEach(item => {
              //List.Log("Found a row in list "+this.title);

              /*
              Row.GetRowFromValues(item.val()).then(row => {
                if (row) {
                  this.Rows.push(row as Row);
                }
              });*

              let row = Row.GetRowFromValues(item.val());
              if (row) this.Rows.push(row);
              TriggerDataRefreshEvent.emit("row");

            });
          }).then(() => { 
            TriggerDataRefreshEvent.emit("list");
            //TriggerDataRefreshEvent.emit("data loaded: list columns  & rows");
            this.updateProperties();
            //resolve(true) 
          }).finally(() => {
            List.Log("**List properties loaded");
            this.ListPropertiesLoaded = true;
          });

        }).catch(() => { });        
      }
      catch(err) { ; }*/
    //});


    return new Promise((resolve, reject) => {
    let cells: Cell[] = [];

    try {
      this.Columns = [];
      //List.Log("** Loading columns in list "+this.id);

      database.ref(Constants.DB_COLUMNS).orderByChild("listItemId").equalTo(this.id).once("value").then(snapshot1 => {
        snapshot1.forEach(item1 => {
          let col = Column.GetColumnFromValues(item1.val());
          if (col) this.Columns.push(col);

          TriggerDataRefreshEvent.emit("list");
        });
      })
      .then(() => {
        List.Log("** "+this.Columns.length+" columns loaded, loading cells in list "+this.id+" ("+this.title+")");
        //cells = [];
        this.Cells = [];
        
        database.ref(Constants.DB_CELLS).orderByChild("listItemId").equalTo(this.id).once("value").then(snapshot2 => {
          snapshot2.forEach(item2 => {
            
            let cell = Cell.GetCellFromValues(item2.val());
            if (cell) {
              //this.Cells.push(cell);
              try {
                if (!this.Cells[cell.rowId])
                  this.Cells[cell.rowId] = [];
                //if (!this.Cells[cell.rowId][cell.columnId])
                //  this.Cells[cell.rowId][cell.columnId] = new Cell(0, 0, 0, 0, 0);
                this.Cells[cell.rowId][cell.columnId] = cell;
              }
              catch (err) { List.Log("Error loading cell: "+err); }
              
            }
            
            TriggerDataRefreshEvent.emit("cell");
          });
        })
      .then(() => {
          List.Log("** "+cells.length+" cells loaded, loading rows in list "+this.id+" ("+this.title+")");
          this.Rows = [];
          database.ref(Constants.DB_ROWS).orderByChild("listItemId").equalTo(this.id).once("value").then(snapshot3 => {
            snapshot3.forEach(item3 => {
                let row = Row.GetRowFromValues(item3.val()); //cells.filter(cell => {cell.rowId == row.id})
                
                if (row) { 
                  this.Rows.push(row);
                }
                TriggerDataRefreshEvent.emit("row");  
            });
          }).then(() => { 
            

          }).finally(() => {
            TriggerDataRefreshEvent.emit("list");
            this.Columns = this.Columns.sort(function(a, b) {return (a.position - b.position)});
            this.Rows = this.Rows.sort(function(a, b) {return (a.position - b.position)});

            List.Log("** "+this.Rows.length+" rows loaded in list "+this.id+" ("+this.title+")");
            this.checkForNilCells();
            this.updateProperties();

            List.Log("**List properties loaded for list "+this.id);
            this.ListPropertiesLoaded = true;
            this.ListLoadCompleted = true; 
            
           // if (callback)
           //   callback(this);
           resolve(true);
          });

        }).catch(() => { }); 
      });
    }
    catch(err) { }
  });
  }

 

  async updateServer(updateSubClasses: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        
        List.Log("Updating list "+this.title);
        TriggerDataRefreshEvent.emit("list");
    
        if (this.id == "0" || this.id == "-1")
          this.id = AFSDB.createId();
    
          database.ref(Constants.DB_LISTS+"/"+this.id).update({
          id: this.id,
          position: this.position,
          title: this.title,
          enabled: this.enabled,
          deleted: this.deleted,
          userId: this.userId,
          showOptionsColumn: this.showOptionsColumn,
          showRowNumberColumn: this.showRowNumberColumn,
          folderId: this.folderId,
          listStatus: this.listStatus,
          listDescription: this.listDescription,
          isPublic: this.isPublic,
        }).then(() => {
    
          if (updateSubClasses) {
            //this.Columns.forEach(col => col.updateServer(true));
            //this.Rows.forEach(row => row.updateServer(true));
            //this.updateServer_ColumnsOnly();
            //this.updateServer_RowsOnly();

            let pos = 1;

            this.Columns.sort(function(a, b) {return (a.position - b.position)}).forEach(col => {
              if (!col.deleted) col.position = pos++;
              col.updateServer(true)
            });

            pos = 1;

            this.Rows.sort(function(a, b) {return (a.position - b.position)}).forEach(row => {
              if (!row.deleted) row.position = pos++;
              row.updateServer(true)
            });

            //this.Cells.forEach(cell => cell.updateServer(true));
            this.Rows.forEach(row => {
              this.Columns.forEach(col => {
                this.Cells[row.id][col.id].updateServer(true);
              });
            });
          }

          this.updateProperties();

          resolve(true);
        }).catch(() => resolve(false));

      }
      catch (err) { reject(err) }
    });
  }

  updateProperties() {
    this.updateProperties_columnWidths();
    this.updateProperties_rows();

  }

  updateProperties_columnWidths() {
    this.totalColumnWidth = this.getTotalColumnWidth();
    this.dataColumnsWidth = this.getDataColumnsWidth();
  }

  updateProperties_rows() {
    this.Rows = this.Rows.sort(function(a, b) {return (a.position - b.position)});
    this.Rows.forEach(row => {
      row.IsRowParentExpanded = this.isRowExpanded(this.getRowById(row.parent));

      let rowClass = "list-row list-row"+this.getRowLevel(row);
      //if (row.isHighlighted)
      //  rowClass += " list-row-highlight";
      row.RowClass = rowClass;

      row.NumberOfSubRows = this.numberOfTotalSubrows(row);
    });
    
  }



  async updateServer_ColumnsOnly(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        TriggerDataRefreshEvent.emit("list");
        let pos = 1;

        this.Columns.sort(function(a, b) {return (a.position - b.position)}).forEach(col => {
          if (!col.deleted) col.position = pos++;
          col.updateServer(false)
        });

        resolve(true);
      }
      catch(err) {reject(err)}
    });
  }

  async updateServer_RowsOnly(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        TriggerDataRefreshEvent.emit("list");
        let pos = 1;

        this.Rows.sort(function(a, b) {return (a.position - b.position)}).forEach(row => {
          if (!row.deleted) row.position = pos++;
          row.updateServer(false);
        });

        //Cell saving not implemented here, not considered necessary

        resolve(true);
      }
      catch (err) {reject(err)}
    });

  }

  delete() {
    List.Log("Deleting list "+this.title);

    this.deleted = true;
    
    this.updateServer(false);
  }

  private getTotalColumnWidth(): number {
    let columnsWidthTotal = (this.showOptionsColumn) ? 105 : 35; //100; //Starting point includes the options columns at the start (70px) and child node toggles (35px)
    if (this.showRowNumberColumn) columnsWidthTotal += 35;

    columnsWidthTotal += this.getDataColumnsWidth();

    return columnsWidthTotal;
  }

  private getDataColumnsWidth(): number {
    let columnsWidthTotal = 0;

    this.Columns.forEach( col => {
      if (col.enabled && !col.deleted) columnsWidthTotal += col.width;
    });

    return columnsWidthTotal;
  }

  private getColumns(): Column[] {
    return this.Columns.filter(col => !col.deleted).sort(function(a, b) {return (a.position - b.position)});
  }

  moveColumn(previousIndex: number, currentIndex: number): void {
    let previousPosition = this.Columns[previousIndex].position;
    let newPosition = this.Columns[currentIndex].position;

    this.Columns.forEach( col => {
        if (newPosition > previousPosition && col.position > previousPosition && col.position <= newPosition) col.position--;
        if (newPosition < previousPosition && col.position < previousPosition && col.position >= newPosition) col.position++;
    });

    this.Columns[previousIndex].position = newPosition;
    TriggerDataRefreshEvent.emit("list");
    this.updateServer_ColumnsOnly();
  }

  addNewColumnAtEnd(): Column {
    List.Log("Adding new column in list "+this.title);
    let newCol = new Column(AFSDB.createId(), this.getNewColumnPosition(), "[New "+this.getNewColumnPosition()+"]", 50, true, false, Column.DataType.Text, this.id);
    this.Columns.push(newCol);
    TriggerDataRefreshEvent.emit("list");
    newCol.updateServer(true);
    this.updateProperties();
    this.checkForNilCells();
    
    return newCol;
  }

  getNewColumnPosition(): number {
    let pos = 0;
    this.Columns.forEach(column => {
      if (column.position > pos)
        pos = column.position;
    });
    return pos+1;
  }

  private getRows(): Row[] {
    if (!this.Rows) return [];
    return this.Rows.filter(row => !row.deleted).sort(function(a, b) {return (a.position - b.position)});
  }

  getRowAndAllSubRows(row: Row): Row[] {
    let numSubRows = this.numberOfTotalSubrows(row);

    return this.getRows().slice(row.position-1, row.position+numSubRows);
  }

  rowHasSameParentAsAbove(row: Row): boolean { //Allow indents if parents are the same
    if (!this.getRowByPosition(row.position-1))
      return true;
    if (row.position==1)
      return false;
    if (this.getRowByPosition(row.position-1).parent == row.parent)
      return true;

    //However, we do want to allow indents at the bottom of a sub list
    //If the row immediately above does not have the same parent, but also has no children, then allow indent
    if (this.numberOfImmediateSubRows(this.getRowByPosition(row.position-1))==0)
      return true;

    return false;
  }

  getRowByPosition( position: number): Row {
    let row: Row = null;

    this.getRows().forEach(ref => {
      if (ref.position == position)
        row = ref;
    });

    return row;
  }

  numberOfImmediateSubRows(parentRow: Row): number { 
    let children: number = 0;
    this.getRows().forEach(row => (row.parent == parentRow.id) ? children++ : null); 
    return children;
  }

  numberOfTotalSubrows(row: Row): number {
    if (!row)
      return 0;

    let rowPos = row.position;
    let num = 0;
    let end = false;
  

    //This is causing issues too - Need to replace this with something else that counts actual sub rows, possibly 3x nested for loops?
    //First, go through rowData and count all rows with parent id same as row.id
    //For each item that matches, check again for any rows with parent id that match that rows id
    //For each item that matches, etc....
    //Only need 3 levels
    this.getRows().forEach ( ref => {
      if (ref.position > rowPos && this.getRowLevel(ref)<=this.getRowLevel(row)) 
        end = true;
      else if (ref.position > rowPos && ref.parent != row.parent && !end) 
        num++;
    });

    return num;
  }

  getRowLevel(row: Row): number {
    let level = 0;
    let parent = row.parent;

    if (row.parent=="0")
        return 0;

    for (let i=1; i<=10; i++) {
      this.getRows().forEach(ref => {
        if (ref.id == parent)
          parent = ref.parent;
      });

      if (parent == "0")
        return i;
    }
    return 0;
  }

  addNewRow(selectedRow: Row, isAfter: boolean, parentRow: Row): Row {
    let currentRowPosition = 0;
    if (selectedRow)
      currentRowPosition = selectedRow.position;
    else { //selectedRow is null so add to the bottom of the list
      this.getRows().forEach(row => {if (row.position>=currentRowPosition) currentRowPosition=row.position});
    }

    let parentRowId = (parentRow) ? parentRow.id : "0";

    List.Log("Adding new row by position "+currentRowPosition + " with parent id "+parentRowId + " on list "+this.title);
    
    let newRow: Row;

    if (isAfter) {
      this.getRows().forEach(row => (row.position>currentRowPosition) ? row.position++ : null); //Reposition all rows following the new row
      newRow = new Row (AFSDB.createId(), currentRowPosition+1, true, parentRowId, true, false, this.id, Date.now(), -1, false, false, "", false, false);
    }
    else {
      this.getRows().forEach(row => (row.position>=currentRowPosition) ? row.position++ : null); //Reposition all rows following the new row
      newRow = new Row (AFSDB.createId(), currentRowPosition, true, parentRowId, true, false, this.id, Date.now(), -1, false, false, "", false, false);
    }

   
    this.Rows.push(newRow);
    this.updateProperties_rows();
    TriggerDataRefreshEvent.emit("list");
    //newRow.updateServer(true);
    this.updateServer_RowsOnly();
    this.checkForNilCells();

    return newRow;
  }

  getRowById(id: string): Row {
    try {
      //let tempRow = this.Rows.filter(row => {row.id == id})[0]; - Not returning any results for some reason
      // let tempRow = this.Rows.filter(row => 
      let tempRow = null
      this.getRows().forEach(row => {if (row.id == id) tempRow = row});
      //List.Log("getRowById result for id "+id+" - "+tempRow);
      if (tempRow)
        return tempRow;
      return null;
    }
    catch(err) { 
      List.Log("Error in getRowById - "+err);
      return null; 
    }
  }

  moveRow(previousIndexInLevel: number, currentIndexInLevel: number) {
    List.Log("Starting moveRow()");

    let sortedRows = this.getRows();
    
    let newPosition = sortedRows[currentIndexInLevel].position;
    let previousPosition = sortedRows[previousIndexInLevel].position;
    let numberOfSubRows = this.numberOfTotalSubrows(sortedRows[previousIndexInLevel]);

    List.Log("previousIndex: "+previousIndexInLevel+", currentIndex: "+currentIndexInLevel+", previousPosition: "+previousPosition+", newPosition: "+newPosition+", num subs: "+numberOfSubRows);

    if (sortedRows[currentIndexInLevel-1]) {
      if (this.numberOfTotalSubrows(sortedRows[currentIndexInLevel-1])>0) {
        List.Log("CI-1 has children, dropped on children, adopt CI-1 ID. Children of CI1="+this.numberOfTotalSubrows(sortedRows[currentIndexInLevel]));
        sortedRows[previousIndexInLevel].parent = sortedRows[currentIndexInLevel-1].id;  
      }
      else if (this.numberOfTotalSubrows(sortedRows[currentIndexInLevel])>0) {
        List.Log("CI has children, dropped on children, adopt CI-1 ID as parent. Children of CI1="+this.numberOfTotalSubrows(sortedRows[currentIndexInLevel]));
        sortedRows[previousIndexInLevel].parent = sortedRows[currentIndexInLevel-1].id;  
      }
      else {
        List.Log("Adopt CI parent (route a)");
        sortedRows[previousIndexInLevel].parent = sortedRows[currentIndexInLevel].parent;
      }
    }
    else {
      List.Log("Adopt CI parent (route b)");
      sortedRows[previousIndexInLevel].parent = sortedRows[currentIndexInLevel].parent;
    }

    
    sortedRows.forEach( row => {
      if (newPosition>previousPosition && row.position>(previousPosition+numberOfSubRows) && row.position<=newPosition) row.position-=(numberOfSubRows+1);
      if (newPosition<previousPosition && row.position>=newPosition && row.position<previousPosition) row.position+=(numberOfSubRows+1);
    });

    if (newPosition>previousPosition) newPosition -= numberOfSubRows;

    List.Log("newPosition: "+newPosition);

    for (let i=0; i<=numberOfSubRows; i++)
      sortedRows[previousIndexInLevel+i].position = newPosition+i;

    List.Log("previousIndex parent: "+sortedRows[previousIndexInLevel].parent+", current parent: "+sortedRows[currentIndexInLevel].parent);

    sortedRows.forEach(row => row.updateServer(false));
    this.updateProperties_rows();
    TriggerDataRefreshEvent.emit("list");
  }

  inheritRowAboveParent(row: Row) {
    List.Log("Inheriting row above parent");
    let rowAbove: Row = this.getRowByPosition(row.position-1);
    if (this.numberOfImmediateSubRows(rowAbove)==0 && rowAbove.parent != row.parent) //Indented at bottom of a list, so inherit the parent of row above
      row.parent = rowAbove.parent;
    else
      row.parent = rowAbove.id;
    
    this.updateProperties_rows();
    
    row.updateServer(false);
  }

  duplicateRow(row: Row) {
    let rowIdTracker = [];
    //let colIdTracker = [];
    let newRows: Row[] = [];
    //let newCells: Cell[] = [];
    let lastPosition = 1;

    this.getRows().forEach(ref => { 
      if (ref.position>=lastPosition) lastPosition = ref.position+1;
    });

    this.getRowAndAllSubRows(row).forEach (ref => {
      let newRow = new Row(AFSDB.createId(), lastPosition++, ref.enabled, ref.parent, ref.expanded, ref.deleted, this.id, Date.now(), -1, ref.isHighlighted, ref.isTextRow, ref.textRowData, ref.textRowIsBold, ref.textRowIsItalic);
      newRows.push(newRow);   
      rowIdTracker[ref.id] = newRow.id;

      /*
      ref.Cells.forEach(cell => {
        let newCell = new Cell(AFSDB.createId(), cell.columnId, newRow.id, cell.cellData, cell.listItemId);
        //this.cellData.push(newCell);
        //this.updateCellOnServer(newCell, false, false);
        newRow.Cells.push(newCell);
      });*/
      /* COPY CELLS */
      this.Columns.forEach(col => {
        let oldCell = this.Cells[ref.id][col.id];
        let newCell = new Cell(AFSDB.createId(), oldCell.columnId, newRow.id, oldCell.cellData, oldCell.listItemId);
        this.Cells.push(newCell);
      });

    });
    
    newRows.forEach(ref => {
      if (rowIdTracker[ref.parent])
        ref.parent = rowIdTracker[ref.parent];
      this.Rows.push(ref);
      //this.updateRowOnServer(ref, false);
    });

    this.moveRow(newRows[0].position-1, row.position+this.getRowAndAllSubRows(row).length-1);
    newRows[0].parent = row.parent;
    //this.updateRowOnServer(newRows[0], true);

    newRows.forEach(row => row.updateServer(true));
  }

  isRowExpanded(row: Row): boolean {
    //Go through each consecutive parent row, if any of them are closed, then return false
    //List.Log("TEMPLATE FUNCTION CALL - isRowExpanded");
    if (!row)
      return true;
    //List.Log("isRowExpanded in "+this.title+" for row parent "+row.id+" ("+row.position+")");
    let parent:string = row.parent;
    let expanded = row.expanded;
    let preventInfinity = 0;

    
    while (parent!="0") {
      //this.log("rowExpanded: parent = "+parent);
      this.getRows().forEach(ref => {
        //this.log("Row parent: "+row.parent+", ref id: "+ref.id+", ref.parent: "+ref.parent+", parent="+parent);
        if (ref.id == parent) {
          parent = ref.parent;
          if (!ref.expanded) expanded = false;
        }
      });
      if (preventInfinity>1000) {
        List.Log("rowExpanded couldn't find parent. Setting expanded=true. THIS ISSUE SHOULD CLEAR WHEN DB DATA IS CLEAN.");
        return true;
      }
      preventInfinity++;
    }

    //List.Log("isRowExpanded in "+this.title+": "+expanded);

    return expanded;
  }

  checkForNilCells() {
    this.getRows().forEach(row => {
      this.getColumns().forEach(col => {
        if (!this.Cells[row.id])
          this.Cells[row.id] = [];
        if (!this.Cells[row.id][col.id]) {
          this.Cells[row.id][col.id] = new Cell(AFSDB.createId(), col.id, row.id, "NC", this.id);
          this.Cells[row.id][col.id].updateServer(true);
        }
      });
    });
  }

}

export class ListPermissions {
  canView: boolean;
  readOnly: boolean;

  constructor () { 
    this.canView = false;
    this.readOnly = true;
  }
}