<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
    Are you sure you want to delete 
    <span *ngIf="data.title!=null">{{data.element}} "{{data.title}}"?</span>
    <span *ngIf="data.title==null">this {{data.element}}?</span>
  <br/>
    <div style="font-style: italic" *ngIf="data.element == 'folder'">
      Any lists in this folder will also be deleted.
    </div>
    <div style="font-style: italic" *ngIf="data.element == 'row'">
      Any nested rows within this row will also be deleted.
    </div>
    <div style="font-style: italic">
        This action cannot be reversed!
    </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="'confirmed'">Delete</button>
  <button mat-button mat-dialog-close cdkFocusInitial style="left: 50px">Cancel</button>
</div>
