import { Component, OnInit, Input, ChangeDetectorRef, NgZone, ChangeDetectionStrategy } from '@angular/core';
import { DateSelectionData, DateSelectionDialogComponent } from '../date-selection-dialog/date-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
//import { ListPermissions } from '../list-container/list-container.component';
import { List, ListPermissions } from '../elements/list';
import { Row } from '../elements/row';
import { Cell } from '../elements/cell';

@Component({
  selector: 'app-list-row-content',
  templateUrl: './list-row-content.component.html',
  styleUrls: ['./list-row-content.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListRowContentComponent implements OnInit {

  @Input() list: List;
  @Input() listPermissions: ListPermissions;
  @Input() row: Row;
  @Input() listBlackoutElement: HTMLDivElement;
  @Input() blackoutClosedEventEmitter: EventEmitter<any>;

  currentCellEdit: HTMLTextAreaElement = null;

  constructor(public dialog: MatDialog, public todoDBService: TodoDBService,
    public changeDetectorRef: ChangeDetectorRef, public zone: NgZone) {
    TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes
    });
  }

  ngOnInit(): void {
    this.blackoutClosedEventEmitter.subscribe(
      next => {
      if (this.currentCellEdit) this.closeEditCell();
      TriggerDataRefreshEvent.emit("list content");
      });
  } 
  
  ngOnDestroy(): void {
    //this.blackoutClosedEventEmitter.unsubscribe();
    this.row = null;
    this.listBlackoutElement = null;
    this.blackoutClosedEventEmitter = null;
    this.currentCellEdit = null;
  }

  editCell(cell: HTMLTextAreaElement): void {
    console.log("Editing: " + cell.value);

    cell.style.height = "";
    cell.style.height = (cell.scrollHeight - 4) + "px";
    cell.style.zIndex = "1000";
    cell.style.background = "white";
    cell.style.paddingLeft = "10px"
    cell.style.paddingRight = "10px";
    cell.style.boxShadow = "5px 10px 18px black"
    cell.style.marginLeft = "-10px";
    cell.readOnly = false;
        
    this.listBlackoutElement.style.display = "block";

    this.currentCellEdit = cell;
    TriggerDataRefreshEvent.emit("list content");
  }

  closeEditCell(): void {
    this.currentCellEdit.style.height = "";
    this.currentCellEdit.style.zIndex = "0";
    this.currentCellEdit.style.background = "";
    this.currentCellEdit.style.paddingLeft = ""
    this.currentCellEdit.style.paddingRight = "";
    this.currentCellEdit.style.boxShadow = "";
    this.currentCellEdit.style.marginLeft = "";
    this.currentCellEdit.readOnly = true;

    this.listBlackoutElement.style.display = "none";

    this.currentCellEdit = null;
    TriggerDataRefreshEvent.emit("list content");
  }

  expandListCellTextArea(cell: HTMLTextAreaElement): void {
    cell.style.height = (cell.scrollHeight - 4) + "px"; 
    TriggerDataRefreshEvent.emit("list content");
  }

  /*Called when the mouse leaves the list cell - Return to normal height unless editing*/
  collapseListCellTextArea(cell: HTMLTextAreaElement): void {
    if (!this.currentCellEdit)
      cell.style.height = "";
    TriggerDataRefreshEvent.emit("list content");
  }

  openDateDialog(row: Row, cell: Cell, showDate: boolean, showTime: boolean): void {
    if (this.listPermissions.readOnly) return;

    this.zone.run(() => {
      let data: DateSelectionData = new DateSelectionData(new Date(cell.cellData), showDate, showTime);
      
      const dialogRef = this.dialog.open(DateSelectionDialogComponent, { data: data });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let convertedValue: number = result.valueOf();
          cell.cellData = convertedValue;
          //this.todoService.updateCellOnServer(cell, true, true);
          cell.updateServer(false);
          row.updateModificationDate()
        }
      });
    });
  }

     /* keyvalue pipe inputs for DataType values */ 
  // Order by ascending property value
  valueAscOrder = (a, b): number => {
    //return a.value.localeCompare(b.value); 
    
    return a.value.position - b.value.position;
    //return -1; 
  }

  cellValueChanged(row: Row, cell: Cell, newValue: string) {
    if (this.listPermissions.readOnly) return;

    cell.cellData = newValue;
    TriggerDataRefreshEvent.emit("list content");
    cell.updateServer(false);
    row.updateModificationDate()
    //this.todoService.updateCellLocally(cell);
    //this.todoService.updateCellOnServer(cell, true, true);
    //this.todoService.updateAllProperties();
  }

  updateRowOnServer(row: Row) {
    if (!this.listPermissions.readOnly) {
      row.updateModificationDate();
    }
  }

}
