<!--<app-list-selection [currentList]="this.currentList" [listPermissions]="listPermissions"></app-list-selection>
<br/>
-->



<span *ngIf="this.currentList && this.currentList.ListLoadCompleted && listPermissions.canView"> <!-- ListViewType.Owner --> 
    <!--<app-list-column-options [list]="this.currentList" [listPermissions]="listPermissions"></app-list-column-options>-->

    <div id="list-container-parent" class="list-container-parent" (window:beforeunload)="this.todoDBService.destroy()">

        <div id="list-header" class="list-header">
            <app-list-header 
            [list]="this.currentList" [listPermissions]="listPermissions"></app-list-header>
        </div>
        
        <app-list-items class="list-items" [list]="this.currentList" [listPermissions]="listPermissions"></app-list-items>
    </div>
    

    <!--
    <div *ngIf="this.currentList">

        <button mat-stroked-button color="primary" (click)="addNewRowAtBottom()" [disabled]="listPermissions.readOnly">
            <mat-icon>playlist_add</mat-icon>
            New row
        </button>
        
    </div>-->
</span> 


<ng-container *ngIf="this.currentList && (!listPermissions.canView)" class="page-base">
    Cannot display list as it is not publicly available.
</ng-container>