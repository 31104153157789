import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, NgZone } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { List, ListPermissions } from '../elements/list';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-ngx';
import { Column } from '../elements/column';
import { Row } from '../elements/row';
import { Cell } from '../elements/cell';

@Component({
  selector: 'app-list-container',
  templateUrl: './list-container.component.html',
  styleUrls: ['./list-container.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListContainerComponent implements OnInit {
  
  public currentList: List;
  //public listViewingStatus: number;
  public listPermissions: ListPermissions;
  
  private navigationSubscription: Subscription;
  private dataRefreshSubscription: Subscription;
  public pageLoadComplete: boolean;
  //public listLoadComplete: boolean;

  public static ListViewType = {
    NotLoaded: 0,
    None: 1,
    Owner: 2,
    ReadOnly: 3,    
  }

  /*private listRawDataArray = [];
  private listRawDataCount=0;
  private listRawDataCountMax=4;*/

  private progressTotalRecords = 0;
  private progressInterated = 0;
  

  constructor(public todoDBService: TodoDBService, public changeDetectorRef: ChangeDetectorRef,
    public route: ActivatedRoute, private router: Router, public zone: NgZone) { 
    this.currentList = null;
    this.listPermissions = new ListPermissions();
    this.pageLoadComplete = true;
    //this.listLoadComplete = false;
    
    this.dataRefreshSubscription = TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes

      if (ref == "user loaded")
        this.loadSelectedList();

      if (ref == "user logout") {
        this.listPermissions = this.getListPermissions();
        TriggerDataRefreshEvent.emit("user");
      }

      /*if (!this.listLoadComplete && this.currentList && this.currentList.ListLoadCompleted) {
        this.listLoadComplete;
        TriggerDataRefreshEvent.emit("progress ended");
      }*/
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (this.pageLoadComplete) this.loadSelectedList();
        console.log("NAVIGATION CHANGED");
      }
    });

    

    
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges(); //Update template for changes
  }

  ngOnDestroy() {
    this.currentList.updateServer(true);
    this.currentList = null;

    this.dataRefreshSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  async loadSelectedList() {
    if (!this.pageLoadComplete)
      return;

    this.pageLoadComplete = false;
    //this.listLoadComplete = false;
    this.todoDBService.displayProgressBar = true;
    
    
    this.currentList = null;
    this.listPermissions = new ListPermissions()
    this.todoDBService.currentList = null;
    this.todoDBService.listPermissions = new ListPermissions()
    //TodoDBService.TriggerDataRefreshEvent.emit("list");
    const listId = this.route.snapshot.paramMap.get('id');

    if (listId) {
      console.log("> List Container: Loading list "+listId); 

      
      if (!this.currentList) { //Not a list owned by the user so not preloaded in their account 
        console.log("> List Container: List not on user account, loading from server");

        if (typeof Worker !== 'undefined') {
          // Create a new worker
          const worker = new Worker('../web-worker-list-load.worker', { type: 'module' });
          worker.onmessage = ({ data }) => {

            this.loadListFromRawData(data);
          };
          //worker.postMessage('hello');
          console.log("> List Container: Loading list from server using Web Worker");
          worker.postMessage(listId);
        } else {
          // Web Workers are not supported in this environment.
          // You should add a fallback so that your program still executes correctly.
          console.log("> List Container: Web Worker not supported, using fallback");
          List.GetListFromId(listId, null).then(list => { this.loadListCallback(list); });
        }

        
      }
      else
        console.log("> List Container: List loaded - "+this.currentList.title + ", number of rows = "+this.currentList.Rows.length);           
    }
    else {
      this.todoDBService.displayProgressBar = false;
    }

    TriggerDataRefreshEvent.emit("list");
  } 

  loadListFromRawData(data) {
    this.todoDBService.progressBarMode = "determinate";
    this.todoDBService.progressBarValue = 0;
    
    let lists = data[0];
    let columns = data[1];
    let rows = data[2];
    let cells = data[3];

    console.log("> List Container: List raw data loaded - "+lists.length+" lists, "+columns.length+" columns, "+rows.length+" rows, "+cells.length+" cells."); 

    /*List.GetListAndPropertiesFromValues(lists[0], columns, rows, cells).then(list => { 
      this.loadListCallback(list); 
    });*/
    let totalRecords = 1 + columns.length + rows.length + cells.length;
    this.progressTotalRecords = totalRecords;
    this.progressInterated = 0;

    List.GetListAndPropertiesFromValues(lists[0], columns, rows, cells, list => this.loadListCallback(list), () => this.iterateProgress() );
  }

  iterateProgress() {
    this.progressInterated++;
    this.todoDBService.progressBarValue = (this.progressInterated / this.progressTotalRecords) * 100;
    //console.log("> List Container: Value="+this.todoDBService.progressBarValue+", iterated="+this.progressInterated+", total="+this.progressTotalRecords);
  }

  loadListCallback(ref: List) {
    console.log("> List Container: List load callback"); 
    if (ref) {
      this.currentList = ref;
      this.listPermissions = this.getListPermissions();
      console.log("> List Container: List loaded from server - "+this.currentList.title);  
      
      this.todoDBService.currentList = ref;
      this.todoDBService.listPermissions = this.getListPermissions();
      
      this.pageLoadComplete = true;
      this.todoDBService.progressBarMode = "indeterminate";
      this.todoDBService.displayProgressBar = false;
      TriggerDataRefreshEvent.emit("list");
    }
  }

  addNewRowAtBottom() {
    console.log("> List Container: Add new row at bottom of list");
    this.currentList.addNewRow(null, true, null);
  }

  getListPermissions(): ListPermissions {
    //this.currentList && (this.currentList.userId==this.todoDBService.CurrentUser.id || this.currentList.isPublic)
    if (!this.currentList) return new ListPermissions();
    let tempPermissions = new ListPermissions();
    
    let uid = "0";
    if (this.todoDBService.CurrentUser)
      uid = this.todoDBService.CurrentUser.id;
    
    if (this.currentList.userId == uid) {
      tempPermissions.canView = true;
      tempPermissions.readOnly = false;
    }
    else if (this.currentList.isPublic) {
      tempPermissions.canView = true;
      tempPermissions.readOnly = true;
    }

    return tempPermissions;  
  }

  /*
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let header = document.getElementById("list-header");
    let content = document.getElementById("list-container-parent");
    let sticky = header.clientTop;

    //console.log("window="+window.pageYOffset+", sticky="+sticky);
    if (window.pageYOffset > sticky+73)  {
      header.classList.add("list-header-sticky");
      //content.classList.add("list-container-parent-sticky");
      content.style.paddingTop = header.clientHeight+"px"; /*NEED TO MAKE SURE THAT CONTENT JUMP = HEIGHT OF HEADER INCL PROPERTIES*
      //console.log("Header height: "+header.clientHeight);
      //console.log("ADDING STICKY");
    }
    else  {
      header.classList.remove("list-header-sticky");
      content.classList.remove("list-container-parent-sticky");
      content.style.paddingTop = "0px";
      //console.log("REMOVING STICKY");
    }
  }*/
  
}

