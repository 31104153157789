<div class="list-row">
    <ng-container *ngIf="this.row.isTextRow && this.listPermissions.canView">
        <textarea
        class="list-row-textrow" #editCellElement matInput placeholder="..." [(ngModel)]="this.row.textRowData" 
        [disabled]="this.listPermissions.readOnly"
        rows=1 readonly [style.width.px]="this.list.totalColumnWidth"
        [class.style-bold]="this.row.textRowIsBold"
        [class.style-italic]="this.row.textRowIsItalic"
        (mouseover)="expandListCellTextArea(editCellElement)"  
        (mouseout)="collapseListCellTextArea(editCellElement)"
        (input)="expandListCellTextArea(editCellElement)" 
        (click)="editCell(editCellElement)"
        (blur)="updateRowOnServer(row)"
        ></textarea>
    </ng-container>

    <!--[style.fontWeight]="row.getRowBoldStyle()"
        [style.fontStyle]="row.getRowItalicStyle()"-->

    <!-- style="text-align: {{ (column.isCenterAligned()) ? 'center' : 'left' }}" -->
    
    <ng-container *ngIf="!this.row.isTextRow && this.list.ListLoadCompleted && this.listPermissions.canView && this.list.Cells[this.row.id]">
           
        <div *ngFor="let column of this.list.Columns">
        <div *ngIf="column.enabled && !column.deleted" class="list-cell" style="text-align: {{ (column.IsCenterAligned) ? 'center' : 'left' }}"
        [style.width.px]="column.width">
            <ng-container *ngIf="this.list.Cells[this.row.id][column.id]">

                <span [ngSwitch]="column.dataType">                   
                    <textarea *ngSwitchCase="'Text'" 
                    class="list-cell-textarea" #editCellElement matInput placeholder="..." [(ngModel)]="this.list.Cells[this.row.id][column.id].cellData"
                    [disabled]="this.listPermissions.readOnly"
                    rows=1 readonly
                    (mouseover)="expandListCellTextArea(editCellElement)"  
                    (mouseout)="collapseListCellTextArea(editCellElement)"
                    (input)="expandListCellTextArea(editCellElement)" 
                    (click)="editCell(editCellElement)"
                    (blur)="this.list.Cells[this.row.id][column.id].updateServer(false); row.updateModificationDate()"
                    ></textarea>

                    <span *ngSwitchCase="'YesNo'">
                        <span [ngSwitch]="this.list.Cells[this.row.id][column.id].cellData">
                            <mat-icon class="check-base check-yes" *ngSwitchCase="'yes'" (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'no')">check_circle_outline</mat-icon>
                            <mat-icon class="check-base check-no" *ngSwitchDefault (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'yes')">highlight_off</mat-icon>
                        </span>
                    </span>

                    <span *ngSwitchCase="'YesNoWarningInprogress'">
                        <span [ngSwitch]="this.list.Cells[this.row.id][column.id].cellData">
                            <mat-icon class="check-base check-yes" *ngSwitchCase="'yes'" (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'no')">check_circle_outline</mat-icon>
                            <mat-icon class="check-base check-warning" *ngSwitchCase="'warning'" (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'yes')">error_outline</mat-icon>
                            <mat-icon class="check-base check-inprogress" *ngSwitchCase="'in progress'" (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'warning')">flip_camera_android</mat-icon>
                            <mat-icon class="check-base check-no" *ngSwitchDefault (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'in progress')">highlight_off</mat-icon>
                        </span>
                    </span>

                    <span *ngSwitchCase="'RobotRedOrangeGreen'">
                        <span [ngSwitch]="this.list.Cells[this.row.id][column.id].cellData">
                            <mat-icon class="check-base check-inprogress" *ngSwitchCase="'caution'" (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'proceed')">stop_circle</mat-icon>
                            <mat-icon class="check-base check-yes" *ngSwitchCase="'proceed'" (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'stop')">stop_circle</mat-icon>
                            <mat-icon class="check-base check-no" *ngSwitchDefault (dblclick)="cellValueChanged(row, this.list.Cells[this.row.id][column.id], 'caution')">stop_circle</mat-icon>
                        </span>
                    </span>


                    <span *ngSwitchCase="'DateTime'">
                        <span (click)="openDateDialog(row, this.list.Cells[this.row.id][column.id], true, true)">
                            {{this.list.Cells[this.row.id][column.id].cellData | dateFormat:"datetime"}}
                        </span>
                    </span>

                    <span *ngSwitchCase="'TimeDate'">
                        <span (click)="openDateDialog(row, this.list.Cells[this.row.id][column.id], true, true)">
                            {{this.list.Cells[this.row.id][column.id].cellData | dateFormat:"timedate"}}
                        </span>
                    </span>

                    <span *ngSwitchCase="'Date'">
                        <span (click)="openDateDialog(row, this.list.Cells[this.row.id][column.id], true, false)">
                            {{this.list.Cells[this.row.id][column.id].cellData | dateFormat:"date"}}
                        </span>
                    </span>

                    <span *ngSwitchCase="'Time'">
                        <span (click)="openDateDialog(row, this.list.Cells[this.row.id][column.id], false, true)">
                            {{this.list.Cells[this.row.id][column.id].cellData | dateFormat:"time"}}
                        </span>
                    </span>

                    <span *ngSwitchCase="'DateCreated'">
                        <span>
                            {{row.dateCreated | dateFormat:"date"}}
                        </span>
                    </span>
                    <span *ngSwitchCase="'TimeCreated'">
                        <span>
                            {{row.dateCreated | dateFormat:"time"}}
                        </span>
                    </span>
                    <span *ngSwitchCase="'DateTimeCreated'">
                        <span>
                            {{row.dateCreated | dateFormat:"datetime"}}
                        </span>
                    </span>

                    <span *ngSwitchCase="'DateModified'">
                        <span>
                            {{row.dateModified | dateFormat:"date"}}
                        </span>
                    </span>
                    <span *ngSwitchCase="'TimeModified'">
                        <span>
                            {{row.dateModified | dateFormat:"time"}}
                        </span>
                    </span>
                    <span *ngSwitchCase="'DateTimeModified'">
                        <span>
                            {{row.dateModified | dateFormat:"datetime"}}
                        </span>
                    </span>





                    <span *ngSwitchDefault> <!-- If data type not found, just print the data in plain text -->
                        [{{this.list.Cells[this.row.id][column.id].cellData}}]
                    </span>
                </span>

            </ng-container>

            </div>
        </div>

    </ng-container>
</div>