<h1 mat-dialog-title>COLUMN OPTIONS</h1>

<hr/>

<div mat-dialog-content class="column-options-card-container" >
    <div class="column-options-card">
        <div *ngIf="column">
            <mat-form-field appearance="fill" [floatLabel]="column.title" [hideRequiredMarker]="true">
                <mat-label>Column title</mat-label>
                <input matInput [(ngModel)]="columnTitleEditing" required>
                <mat-error>Column title is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Data type</mat-label>
                <mat-select [(value)]="columnDataTypeEditing">
                    <mat-option *ngFor="let dataType of this.dataType | keyvalue: valueAscOrder" [value]="dataType.key">{{dataType.value}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="note-text">Note: Changing the data type could result in loss of text data in this column.</div>

            <br/>

            <button mat-stroked-button color="primary" class="button-style" (click)="removeColumn()">
                <mat-icon>delete</mat-icon> 
                <span>Delete</span>
            </button>
            <div class="note-text">Note: Deleting a column cannot be undone. Use with care.</div>
        </div>
      
    </div>
</div>

<hr/>
<div mat-dialog-actions>
    <div style="overflow: auto;">
        <button mat-stroked-button color="primary" class="button-style save-button" (click)="closeAndSaveOptionsDialog()">
            <mat-icon>done</mat-icon> Save
        </button>
        
        <button mat-stroked-button color="primary" class="button-style cancel-button" mat-dialog-close>
            <mat-icon>clear</mat-icon> Cancel
        </button>
    </div>
</div>