<h1 mat-dialog-title>Row card</h1>
<hr/>
<br>
<div mat-dialog-content class="dialog-content" *ngIf="this.data.listPermissions.canView">

    <ng-container *ngIf="this.data.row.isTextRow">
        <textarea
        class="list-row-textrow" matInput placeholder="..." [(ngModel)]="this.data.row.textRowData" 
        rows=3 
        (blur)="this.data.row.updateServer(false)"
        [disabled]="this.data.listPermissions.readOnly"
        ></textarea>
    </ng-container>

    <ng-container *ngIf="!this.data.row.isTextRow">
    
        <div *ngFor="let column of this.data.list.Columns" class="row-data-container">

            <div *ngIf="column.enabled && !column.deleted">
                
                <div class="column-title">
                    {{column.title}}
                </div>
                <div class="cell-data">
                    

                    <span [ngSwitch]="column.dataType">

                        <textarea *ngSwitchCase="'Text'" #editCellElement [(ngModel)]="this.data.list.Cells[this.data.row.id][column.id].cellData"
                        class="list-cell-textarea" matInput placeholder="..." 
                        rows=3
                        (blur)="this.data.list.Cells[this.data.row.id][column.id].updateServer(false); this.data.row.updateModificationDate()"
                        [disabled]="this.data.listPermissions.readOnly"
                        ></textarea>

                        <span *ngSwitchCase="'YesNo'">
                            <span [ngSwitch]="this.data.list.Cells[this.data.row.id][column.id].cellData">
                                <mat-icon class="check-base check-yes" *ngSwitchCase="'yes'" (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'no')">check_circle_outline</mat-icon>
                                <mat-icon class="check-base check-no" *ngSwitchDefault (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'yes')">highlight_off</mat-icon>
                            </span>
                        </span>

                        <span *ngSwitchCase="'YesNoWarningInprogress'">
                            <span [ngSwitch]="this.data.list.Cells[this.data.row.id][column.id].cellData">
                                <mat-icon class="check-base check-yes" *ngSwitchCase="'yes'" (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'no')">check_circle_outline</mat-icon>
                                <mat-icon class="check-base check-warning" *ngSwitchCase="'warning'" (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'yes')">error_outline</mat-icon>
                                <mat-icon class="check-base check-inprogress" *ngSwitchCase="'in progress'" (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'warning')">flip_camera_android</mat-icon>
                                <mat-icon class="check-base check-no" *ngSwitchDefault (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'in progress')">highlight_off</mat-icon>
                            </span>
                        </span>

                        <span *ngSwitchCase="'RobotRedOrangeGreen'">
                            <span [ngSwitch]="this.data.list.Cells[this.data.row.id][column.id].cellData">
                                <mat-icon class="check-base check-inprogress" *ngSwitchCase="'caution'" (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'proceed')">stop_circle</mat-icon>
                                <mat-icon class="check-base check-yes" *ngSwitchCase="'proceed'" (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'stop')">stop_circle</mat-icon>
                                <mat-icon class="check-base check-no" *ngSwitchDefault (dblclick)="cellValueChanged(this.data.list.Cells[this.data.row.id][column.id], 'caution')">stop_circle</mat-icon>
                            </span>
                        </span>


                        <span *ngSwitchCase="'DateTime'">
                            <span (click)="openDateDialog(this.data.list.Cells[this.data.row.id][column.id], true, true)">
                                {{this.data.list.Cells[this.data.row.id][column.id].cellData | dateFormat:"datetime"}}
                            </span>
                        </span> 

                        <span *ngSwitchCase="'TimeDate'">
                            <span (click)="openDateDialog(this.data.list.Cells[this.data.row.id][column.id], true, true)">
                                {{this.data.list.Cells[this.data.row.id][column.id].cellData | dateFormat:"timedate"}}
                            </span>
                        </span>

                        <span *ngSwitchCase="'Date'">
                            <span (click)="openDateDialog(this.data.list.Cells[this.data.row.id][column.id], true, false)">
                                {{this.data.list.Cells[this.data.row.id][column.id].cellData | dateFormat:"date"}}
                            </span>
                        </span>

                        <span *ngSwitchCase="'Time'">
                            <span (click)="openDateDialog(this.data.list.Cells[this.data.row.id][column.id], false, true)">
                                {{this.data.list.Cells[this.data.row.id][column.id].cellData | dateFormat:"time"}}
                            </span>
                        </span>

                        <span *ngSwitchCase="'DateCreated'">
                            <span>
                                {{this.data.row.dateCreated | dateFormat:"date"}}
                            </span>
                        </span>
                        <span *ngSwitchCase="'TimeCreated'">
                            <span>
                                {{this.data.row.dateCreated | dateFormat:"time"}}
                            </span>
                        </span>
                        <span *ngSwitchCase="'DateTimeCreated'">
                            <span>
                                {{this.data.row.dateCreated | dateFormat:"datetime"}}
                            </span>
                        </span>

                        <span *ngSwitchCase="'DateModified'">
                            <span>
                                {{this.data.row.dateModified | dateFormat:"date"}}
                            </span>
                        </span>
                        <span *ngSwitchCase="'TimeModified'">
                            <span>
                                {{this.data.row.dateModified | dateFormat:"time"}}
                            </span>
                        </span>
                        <span *ngSwitchCase="'DateTimeModified'">
                            <span>
                                {{this.data.row.dateModified | dateFormat:"datetime"}}
                            </span>
                        </span>





                        <span *ngSwitchDefault> <!-- If data type not found, just print the data in plain text -->
                            [{{this.data.list.Cells[this.data.row.id][column.id].cellData}}]
                        </span>
                    </span>
                </div>
            </div>
        </div>

    </ng-container>
</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close color="primary">Close</button>
</div>