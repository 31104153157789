import { OnInit } from '@angular/core';
import { Cell } from './cell';
import { TriggerDataRefreshEvent, database, AFSDB } from '../constants';
import { Constants } from '../constants';
import { Column } from './column';
//import { ListPermissions } from '../list-container/list-container.component';
import { ListPermissions } from "./list";

export class Row implements OnInit {
  id: string; //TODO: Change to string
  position: number;
  enabled: boolean;
  parent: string;
  expanded: boolean;
  deleted: boolean;
  listItemId: string;
  dateCreated: number;
  dateModified: number;
  isHighlighted: boolean;
  isTextRow: boolean;
  textRowData: string;
  textRowIsBold: boolean;
  textRowIsItalic: boolean;
  /* DON'T FORGET TO CHANGE SERVER UPDATE CODE FOR CHANGES HERE */ 

  //Cells: Cell[];
  //CellDataLoaded: boolean;
  IsRowParentExpanded: boolean;
  RowClass: string;
  NumberOfSubRows: number;

  constructor (id, position, enabled, parent, expanded, deleted, listItemId, dateCreated, dateModified, isHighlighted, isTextRow, textRowData, textRowIsBold, textRowIsItalic) {
    this.id = id;
    this.position = position;
    this.enabled = enabled;
    this.parent = parent;
    this.expanded = expanded;
    this.deleted = deleted;
    this.listItemId = listItemId;
    this.dateCreated = dateCreated;
    this.dateModified = dateModified;
    this.isHighlighted = isHighlighted;
    this.isTextRow = isTextRow;
    this.textRowData = textRowData;
    this.textRowIsBold = textRowIsBold;
    this.textRowIsItalic = textRowIsItalic;

    //this.Cells = [];
    this.IsRowParentExpanded = true;
    this.RowClass = "";
    this.NumberOfSubRows = 0;
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy();
  }
  

  destroy() {
    //this.Cells.forEach(cell => cell.destroy());
    //this.Cells = null;
  }

  toString() {
    return "id: "+this.id + ", position: "+this.position+", parent: "+this.parent;
  } 

  static Log(message: string): void {
    console.log("> Row: " + message)
  }

  //static async GetRowFromValues(values, cells: Cell[]): Promise<Row> {
  static GetRowFromValues(values): Row {
    //return new Promise((resolve, reject) => {
      
      try {
        
        let tempRow = new Row(values.id, values.position, values.enabled, values.parent, values.expanded, values.deleted, values.listItemId, values.dateCreated, values.dateModified, values.isHighlighted, values.isTextRow, values.textRowData, values.textRowIsBold, values.textRowIsItalic);

        /*tempRow.loadProperties().then(() => {
          TodoDBService.TriggerDataRefreshEvent.emit("row");
          resolve(tempRow);
        }).catch(() => resolve(null));*/
        
        TriggerDataRefreshEvent.emit("row");
        //TriggerDataRefreshEvent.emit("data loaded: row");
        
        //tempRow.Cells = cells.filter(cell => cell.id == tempRow.id);
        //tempRow.Cells = cells;
        //tempRow.CellDataLoaded = true;
        //Row.Log("Finished loading row for list "+tempRow.listItemId);
        tempRow.loadProperties();
        
        return tempRow;
      
      }
      catch(err) {
        Row.Log("Error loading row: " + err);
        //reject("Error loading row: " + err);
        return null;
      }
    //});
  }

  //async loadProperties(): Promise<boolean> {
  loadProperties() {
    //return new Promise((resolve, reject) => {
      /*try {

        this.Cells = [];
        database.ref(Constants.DB_CELLS).orderByChild("rowId").equalTo(this.id).once("value").then(snapshot => {
          snapshot.forEach(item => {
            

            /*Cell.GetCellFromValues(item.val()).then(cell => {
              if (cell) {
                this.Cells.push(cell as Cell);
              }
            });*
            let cell = Cell.GetCellFromValues(item.val());
            //Row.Log("Found a cell for row "+this.id+": "+cell.toString());
            if (cell) this.Cells.push(cell);
            TriggerDataRefreshEvent.emit("row");

          });
        }).finally(() => {//.finally(() => { resolve(true); }).catch(() => {resolve(false)});
          this.CellDataLoaded = true;
          TriggerDataRefreshEvent.emit("row");
          //TriggerDataRefreshEvent.emit("data loaded: row cells");
          Row.Log("Finished loading cells for row "+this.id);
        });
      }
      catch(err) { ; }*/
    //});
    
  }

  async updateServer(updateProperties: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        Row.Log("Updating row "+this.id);
        TriggerDataRefreshEvent.emit("row");
        if (this.id == "0" || this.id == "-1")
          this.id = AFSDB.createId();
    
        database.ref(Constants.DB_ROWS+"/"+this.id).update({
          id: this.id,
          position: this.position,
          enabled: this.enabled,
          parent: this.parent,
          expanded: this.expanded,
          deleted: this.deleted,
          listItemId: this.listItemId,
          dateCreated: this.dateCreated,
          dateModified: this.dateModified,
          isHighlighted: this.isHighlighted,
          isTextRow: this.isTextRow,
          textRowData: this.textRowData,
          textRowIsBold: this.textRowIsBold,
          textRowIsItalic: this.textRowIsItalic,
        }).then(() => {

          /*if (updateProperties) {
            this.Cells.forEach(cell => cell.updateServer(true));
          }*/

        }).finally(() => { resolve(true); }).catch(() => { resolve(false); });
      }
      catch (err) {reject(err)}
    });
  }

  /*
  getCellByColumn(column: Column): Cell {
    //Row.Log("TEMPLATE FUNCTION CALL - getCellByColumn");
    if (!this.CellDataLoaded)
      return null;
    try {
      let cell = this.Cells.filter(cell => cell.columnId==column.id)[0];
      //Row.Log("Getting cell for row/col "+this.id+"/"+column.id+": "+cell.toString());
      //-->CELL DUPLICATION IS OCURRING BECAUSE THIS METHOD IS BEING CALLED BEFORE CELLS ARE FINISHED LOADING
      //SHOULD ONLY BE CALLED, OR ONLY RETURN A RESULT, ONCE ALL LOADED
      //FOR EACH ELEMENT (USER/LIST/ROW/etc) there should be a loading complete flag, which is only set to tru
      // once the element and all its sub elements have loaded
      if (cell)
        return cell;
      else  
        throw("error");
    }
    catch { 
      //No cell found, create a new one
      let newCell = new Cell(AFSDB.createId(), column.id, this.id, "X", this.listItemId);
      this.Cells.push(newCell);
      TriggerDataRefreshEvent.emit("row content");
      //newCell.updateServer(true);
      return newCell;
    }
  }*/

  delete() {
    Row.Log("Deleting row "+this.id);

    this.deleted = true;
    
    this.updateServer(false);
  }

  toggleRowExpand(listPermissions: ListPermissions) {
    Row.Log("toggleRowExpand for row "+this.id);
    
    this.expanded = !this.expanded;
    if (!listPermissions.readOnly)
      this.updateServer(false);
  }

  toggleHighlight() {
    Row.Log("toggleHighlight for row "+this.id);
    
    this.isHighlighted = !this.isHighlighted;
    this.updateServer(false);
  }

  toggleTextRow() {
    Row.Log("toggleTextRow for row "+this.id);
    
    this.isTextRow = !this.isTextRow;
    this.updateServer(false);
  }

  toggleTextRowBold() {
    Row.Log("toggleTextRowBold for row "+this.id);
    
    this.textRowIsBold = !this.textRowIsBold;
    this.updateServer(false);
  }

  toggleTextRowItalic() {
    Row.Log("toggleTextRowItalic for row "+this.id);
    
    this.textRowIsItalic = !this.textRowIsItalic;
    this.updateServer(false);
  }


  private getRowBoldStyle(): string {
    //Row.Log("TEMPLATE FUNCTION CALL - getRowBoldStyle");
    return (this.textRowIsBold) ? "bold" : "normal";
  }

  private getRowItalicStyle(): string {
    //Row.Log("TEMPLATE FUNCTION CALL - getRowItalicStyle");
    return (this.textRowIsItalic) ? "italic" : "normal";
  }

  updateModificationDate() {
    this.dateModified = Date.now();
    this.updateServer(false);
  }

}