import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireModule } from 'angularfire2';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select'
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListItemsComponent } from './list-items/list-items.component';
import { ListHeaderComponent } from './list-header/list-header.component';
import { ListContainerComponent } from './list-container/list-container.component';
import { ListColumnOptionsComponent } from './list-column-options/list-column-options.component';
import { ListSelectionComponent } from './list-selection/list-selection.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { DateSelectionDialogComponent } from './date-selection-dialog/date-selection-dialog.component';
import { DateFormatPipe } from './date-format.pipe';
import { ListRowContentComponent } from './list-row-content/list-row-content.component';
import { ListOptionsDialogComponent } from './list-options-dialog/list-options-dialog.component';
import { ListColumnOptionsDialogComponent } from './list-column-options-dialog/list-column-options-dialog.component';
import { ListRowCardDialogComponent } from './list-row-card-dialog/list-row-card-dialog.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireStorageModule } from 'angularfire2/storage';
import {MatMenuModule} from '@angular/material/menu';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageNewsComponent } from './page-news/page-news.component';
import { PageContactComponent } from './page-contact/page-contact.component';
import { PageAdminComponent } from './page-admin/page-admin.component';
import { PageAboutComponent } from './page-about/page-about.component';
import { PageExamplesComponent } from './page-examples/page-examples.component';
import { PageTemplatesComponent } from './page-templates/page-templates.component'; 
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AppComponent,
    ListItemsComponent,
    ListHeaderComponent,
    ListContainerComponent,
    ListColumnOptionsComponent,
    ListSelectionComponent,
    ConfirmationDialogComponent,
    InformationDialogComponent,
    DateSelectionDialogComponent,
    DateFormatPipe,
    ListRowContentComponent,
    ListOptionsDialogComponent,
    ListColumnOptionsDialogComponent,
    ListRowCardDialogComponent,
    UserLoginComponent,
    PageHomeComponent,
    PageNewsComponent,
    PageContactComponent,
    PageAdminComponent,
    PageAboutComponent,
    PageExamplesComponent,
    PageTemplatesComponent
  ],
  imports: [ 
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    MatCheckboxModule,
    FormsModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatDialogModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    AngularFireStorageModule,
    MatToolbarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
    OverlayscrollbarsModule,
    MatProgressBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }