import { OnInit } from '@angular/core';
import { TriggerDataRefreshEvent, database, AFSDB } from '../constants';
import { Constants } from '../constants';

export class Cell implements OnInit {
  id: string; //TODO: Change to string
  columnId: string;
  rowId: string;
  cellData: any; 
  listItemId: string;
  /* DON'T FORGET TO CHANGE SERVER UPDATE CODE FOR CHANGES HERE */ 

  constructor (id, columnId, rowId, cellData, listItemId) {
    this.id = id;
    this.columnId = columnId;
    this.rowId = rowId;
    this.cellData = cellData;
    this.listItemId = listItemId;
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy();
  }
  

  destroy() {

  }

  toString() {
    return "id: '"+this.id+"', columnId: '"+this.columnId+"', rowId: '"+this.rowId+"', cellData: '"+this.cellData+"'";
  }

  static Log(message: string): void {
    console.log("> Cell: " + message)
  }

  //static async GetCellFromValues(values): Promise<Cell> {
  static GetCellFromValues(values): Cell {
    //return new Promise((resolve, reject) => {
      try {
        
        let tempCell = new Cell(values.id, values.columnId, values.rowId, values.cellData, values.listItemId);
        /*tempCell.loadProperties().then(() => {

          TodoDBService.TriggerDataRefreshEvent.emit("cell");
          resolve(tempCell);

        }).catch(() => { resolve(null) });*/
        //Cell.Log("Finished loading cell for list "+tempCell.listItemId);
        tempCell.loadProperties();
        return tempCell;

      }
      catch(err) {
        Cell.Log("Error loading cell: " + err);
        //reject("Error loading cell: " + err);
        return null;
      }
    //});
  }

  //async loadProperties(): Promise<boolean> {
  loadProperties() {
    /*return new Promise((resolve, reject) => {
      try {

        resolve(true);

      }
      catch (err) { reject(err) }
    });*/
    TriggerDataRefreshEvent.emit("cell");
  }

  async updateServer(updateProperties: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        Cell.Log("Updating cell "+this.id);
        TriggerDataRefreshEvent.emit("cell");
        if (this.id == "0" || this.id == "-1")
          this.id = AFSDB.createId();
    
        database.ref(Constants.DB_CELLS+"/"+this.id).update({
          id: this.id,
          columnId: this.columnId,
          rowId: this.rowId,
          cellData: this.cellData,
          listItemId: this.listItemId,
        }).then(() => {
      
          if (updateProperties) {
    
          }
  

        }).finally(() => { resolve(true) }).catch(() => { resolve(false) });
    

      }
      catch(err) { reject(err) }
    });
  }

}