import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DateSelectionData, DateSelectionDialogComponent } from '../date-selection-dialog/date-selection-dialog.component';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
//import { ListPermissions } from '../list-container/list-container.component';
import { Cell } from '../elements/cell';
import { List, ListPermissions } from '../elements/list';
import { Row } from '../elements/row';

@Component({
  selector: 'app-list-row-card-dialog',
  templateUrl: './list-row-card-dialog.component.html',
  styleUrls: ['./list-row-card-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListRowCardDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ListRowCardDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog, public todoDBService: TodoDBService,
    public changeDetectorRef: ChangeDetectorRef, public zone: NgZone) { 
      TriggerDataRefreshEvent.subscribe(ref => {
        //this.changeDetectorRef.detectChanges(); //Update template for changes
      });
    }

  ngOnInit(): void {
  }

  openDateDialog(cell: Cell, showDate: boolean, showTime: boolean): void {
    if (this.data.listPermissions.readOnly) return;
    
    let data: DateSelectionData = new DateSelectionData(new Date(cell.cellData), showDate, showTime);

    this.zone.run(() => {
      const dialogRef = this.dialog.open(DateSelectionDialogComponent, { data: data });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let convertedValue: number = result.valueOf();
          cell.cellData = convertedValue;
          //this.todoService.updateCellOnServer(cell, true, true);
          cell.updateServer(false);
          this.data.row.updateModificationDate()
        }
      });
    });
  }

       /* keyvalue pipe inputs for DataType values */ 
  // Order by ascending property value
  valueAscOrder = (a, b): number => {
    //return a.value.localeCompare(b.value); 
    
    return a.value.position - b.value.position;
    //return -1; 
  }

  cellValueChanged(cell: Cell, newValue: string) {
    if (this.data.listPermissions.readOnly) return;

    cell.cellData = newValue;
    //this.todoService.updateCellLocally(cell);
    //this.todoService.updateCellOnServer(cell, true, true);
    //this.todoService.updateAllProperties();
    cell.updateServer(false);
    this.data.row.updateModificationDate()
  }
}

export interface DialogData {
  list: List,
  row: Row,
  listPermissions: ListPermissions,
}