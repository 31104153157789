import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, NgZone, ApplicationRef, Input, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ListOptionsDialogComponent } from '../list-options-dialog/list-options-dialog.component';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
import { Router } from '@angular/router';
//import { ListPermissions } from '../list-container/list-container.component';
import { List, ListPermissions } from '../elements/list';
import { ListFolder } from '../elements/listfolder';

@Component({
  selector: 'app-list-selection',
  templateUrl: './list-selection.component.html',
  styleUrls: ['./list-selection.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ListSelectionComponent implements OnInit {

  @Input() currentList: List;
  @Input() listPermissions: ListPermissions;

  //selectedListItem: ListItem = null;
  currentCellEdit: HTMLTextAreaElement = null;


  constructor(public todoDBService: TodoDBService, 
    public dialog: MatDialog, public changeDetectorRef: ChangeDetectorRef,
    public zone: NgZone, public router: Router) { 
    TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes
    });
  }

  ngOnInit(): void {
    //this.selectedListItem = this.todoService.getListData()[0];
  }

  ngOnDestroy(): void {
    this.currentCellEdit = null;
  }

  log(message: string) {
    console.log("> List Selection Component: "+message);
  }

  newList(): void {
    this.todoDBService.CurrentUser.addNewList();
  }

  newListFolder(): void {
    this.todoDBService.CurrentUser.addNewListFolder();
  }

  selectList(listItem: List): void {
    /*this.zone.run(() => { //Needed in order for list status items to update immediately
      this.todoDBService.CurrentUser.setSelectedList(listItem);
    });*/
    if (!this.currentList || listItem.id != this.currentList.id)
      this.router.navigate(["list/"+listItem.id]);
    //this.router.navigate(["/list"], {queryParams: {id: listItem.id},preserveFragment: true});
    
    TriggerDataRefreshEvent.emit("list selection");

  }

  /*
  updateListStatus(listItem: List, newStatus: string): void {
    if (this.listPermissions.readOnly) return;

    this.zone.run(() => {
      listItem.listStatus = newStatus;
      listItem.updateServer(false);
    });
    TriggerDataRefreshEvent.emit("list selection");
  }*/

  
  isSelected(listItem: List): boolean {
    //this.log("TEMPALTE FUNCTION CALL: Checking background colour");
    if (!this.currentList)
      return false;
    if (listItem.id == this.currentList.id)
      return true; 
    return false;
  }

  deleteFolder(folderItem: ListFolder) {
    this.zone.run(() => { //Needed in order for dialog to display correctly
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {data: {element: "folder", title: folderItem.title}});

      dialogRef.afterClosed().subscribe(result => {
        if (result=="confirmed")
          folderItem.delete(true);
      });
    });
    
  }

  openListMenu(list: List) {
    if (list.Columns.length == 1  && list.Rows.length == 1)
      null;//list.loadProperties(() => this._openListMenu(list)); REMOVED WHILE SORTING OUT LOADING ANIMATIONS
    else 
      this._openListMenu(list)
  }

  _openListMenu(list: List) {
    this.zone.run(() => {  //Needed in order for dialog to display correctly
      this.dialog.open(ListOptionsDialogComponent, {data: list});
    });
  }

  clickBlackout(blackoutElement: HTMLElement): void {
    blackoutElement.style.display = "none";
    this.closeEditCell();
    TriggerDataRefreshEvent.emit("list selection");
  }

  closeEditCell(): void {
    this.currentCellEdit.style.height = "";
    this.currentCellEdit.style.zIndex = "0";
    this.currentCellEdit.style.background = "";
    this.currentCellEdit.style.paddingLeft = ""
    this.currentCellEdit.style.paddingRight = "";
    this.currentCellEdit.style.boxShadow = "";
    this.currentCellEdit.style.marginLeft = "";
    this.currentCellEdit.readOnly = true;

    this.currentCellEdit = null;
    TriggerDataRefreshEvent.emit("list selection");
  }

  editCell(blackoutElement: HTMLElement, cell: HTMLTextAreaElement): void {
    //this.style.height=''; this.style.height = (this.scrollHeight-4) + 'px'
    cell.style.height = "";
    //cell.style.height = (cell.scrollHeight - 4) + "px";
    cell.style.zIndex = "1000";
    cell.style.background = "white";
    cell.style.paddingLeft = "10px"
    cell.style.paddingRight = "10px";
    cell.style.boxShadow = "5px 10px 18px black"
    cell.style.marginLeft = "-10px";
    //cell.style.marginLeft = "20px";

    cell.readOnly = false;

    blackoutElement.style.display = "block";

    this.currentCellEdit = cell;
    TriggerDataRefreshEvent.emit("list selection");
  }

  drop(event: CdkDragDrop<string[]>) {
    //this.todoService.moveList(event.previousIndex, event.currentIndex);

    let previousIndex = event.previousIndex-1; //Exclude folder titla which has cdkDrag on it for list dragging to work
    let currentIndex = event.currentIndex-1;

    let previousFolder: string = (event.previousContainer.data) ? event.previousContainer.data["id"] : "-1";
    let newFolder: string = (event.container.data) ? event.container.data["id"] : "-1";

    console.log("Moved list from folder "+previousFolder+" to "+newFolder+". Old index: "+previousIndex+". New index: "+currentIndex);
    //this.todoService.moveList(previousIndex, currentIndex, previousFolder, newFolder);
    this.todoDBService.CurrentUser.moveList(previousIndex, currentIndex, previousFolder, newFolder);
  }

  valueAscOrder = (a, b): number => {
    //return a.value.localeCompare(b.value); 
    
    return a.value.position - b.value.position;
    //return -1; 
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let header = document.getElementById("list-container");
    let sticky = header.clientTop;

    //console.log("window="+window.pageYOffset+", sticky="+sticky);
    if (window.pageYOffset > sticky+60)  {
      header.classList.add("list-container-sticky");
      //console.log("ADDING STICKY");
    }
    else  {
      header.classList.remove("list-container-sticky");
      //console.log("REMOVING STICKY");
    }
  }
  
}

