<div class="page-base">
    <div class="page-header">
        Examples
    </div>

    <p style="text-align: center;">
        Below are a few examples of Exenea in action. More examples will be added once the alpha test release is available.
    </p>

    <div class="examples-container">
        <div>
            <div>
                A simple todo list
            </div>
            <img class="screenshot-container screenshot-container-STD" src="assets/0.3p-todo.PNG"/>
        </div>

        <div class="margin-top-10">
            <div>
                Project tracker
            </div>
            <img class="screenshot-container screenshot-container-BPT" src="assets/0.3p-BPT.PNG"/>
        </div>

        <div class="margin-top-10">
            <div>
                Client feedback tracker
            </div>
            <img class="screenshot-container screenshot-container-CF" src="assets/0.3p-CF.PNG"/>
        </div>

        <div class="margin-top-10">
            <div>
                Finance month-end tasks
            </div>
            <img class="screenshot-container screenshot-container-FME" src="assets/0.3p-ME.PNG"/>
        </div>
    </div>
    
</div>