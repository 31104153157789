import { OnInit } from '@angular/core';
import { TriggerDataRefreshEvent, database, AFSDB } from '../constants';
import { Constants } from '../constants';
  
export class Column implements OnInit {
  id: string; //TODO: Change to string
  position: number;
  title: string;
  width: number;
  enabled: boolean;
  deleted: boolean; /* When importing from server, always filter for not deleted for efficiency */
  dataType: string;
  listItemId: string;
  /* DON'T FORGET TO CHANGE SERVER UPDATE CODE FOR CHANGES HERE */

  IsCenterAligned: boolean;

  constructor (id, position, title, width, enabled, deleted, dataType, listItemId) {
    this.id = id;
    this.position = position;
    this.title = title;
    this.width = width;
    this.enabled = enabled;
    this.deleted = deleted;
    this.dataType = dataType;
    this.listItemId = listItemId;

    this.IsCenterAligned = this.isCenterAligned();
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy();
  }

  destroy() {

  }

  toString() {
    return this.id + " ("+this.title+")";
  }

  public static DataType = {
    Text: "Text",
    YesNo: "Status - Yes/No",
    YesNoWarningInprogress: "Status - Yes/No/Warning/In progress",
    RobotRedOrangeGreen: "Status - Red/Orange/Green", 
    Time: "Dates - Time only", 
    Date: "Dates - Date only", 
    DateTime: "Dates - Date & time", 
    TimeDate: "Dates - Time & date", 
    Location: "Location", /* IMPLEMENT */
    Person: "Person", /* IMPLEMENT - People who are assigned to list, associated with user, and part of organisation*/
    DateCreated: "Row information - Created date", 
    TimeCreated: "Row information - Created time",
    DateTimeCreated: "Row information - Created date & time",
    DateModified: "Row information - Modified date", 
    TimeModified: "Row information - Modified time",
    DateTimeModified: "Row information - Modified date & time",
    Number: "Number", /* IMPLEMENT - Fomat as integer */
    SmileyHappyNeutralSad: "Smiley - Happy/Neutral/Sad", /* IMPLEMENT */ 
  }

  static Log(message: string): void {
    console.log("> Column: " + message)
  }

  //static async GetColumnFromValues(values): Promise<Column> {
  static GetColumnFromValues(values): Column {
    //return new Promise((resolve, reject) => {
      try {
        let tempColumn = new Column(values.id, values.position, values.title, values.width, values.enabled, values.deleted, values.dataType, values.listItemId);

        /*
        tempColumn.loadProperties().then(() => {
          TodoDBService.TriggerDataRefreshEvent.emit("column");
          resolve(tempColumn);
        }).catch(() => resolve(null));*/
        //Column.Log("Finished loading column for list "+tempColumn.listItemId);
        tempColumn.loadProperties();
        return tempColumn;
        
      }
      catch(err) {
        Column.Log("Error loading column: " + err);
        //reject("Error loading column: " + err);
        return null;
      }
    //});
  }

  //async loadProperties(): Promise<boolean> {
  loadProperties()  {
    /*return new Promise((resolve, reject) => {
      try {
        resolve(true);
      }
      catch(err) {reject(err)}
    });*/
    this.IsCenterAligned = this.isCenterAligned();
    TriggerDataRefreshEvent.emit("column");
  }

  async updateServer(updateProperties: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        Column.Log("Updating column "+this.title);
        TriggerDataRefreshEvent.emit("column");
        if (this.id == "0" || this.id == "-1")
          this.id = AFSDB.createId();
    
        database.ref(Constants.DB_COLUMNS+"/"+this.id).update({
          id: this.id,
          position: this.position,
          title: this.title,
          width: this.width,
          enabled: this.enabled,
          deleted: this.deleted,
          dataType: this.dataType,
          listItemId: this.listItemId,
        }).then(() => {
          if (updateProperties) {
          
          }
  
          resolve(true);
        }).catch(() => resolve(false));   
      }
      catch(err) {reject(err)}
    });
  }

  delete() {
    Column.Log("Deleting column "+this.title);

    this.deleted = true;
    
    this.updateServer(false);
  }

  private isCenterAligned(): boolean {
    //Column.Log("TEMPLATE FUNCTION CALL - isCenterAligned");
    if (this.dataType == "YesNo" || this.dataType == "YesNoWarningInprogress" || this.dataType=="RobotRedOrangeGreen" || this.dataType=="SmileyHappyNeutralSad")
      return true;
    return false;
  }
}