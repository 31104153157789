import { Injectable, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { List } from './list';
import { TriggerDataRefreshEvent, database, AFSDB } from '../constants';
import { Constants } from '../constants';
import { Subscription } from 'rxjs';

export class ListFolder implements OnInit {
  id: string;
  title: string;
  deleted: boolean;
  userId: string;
  expanded: boolean;
  /* DON'T FORGET TO CHANGE SERVER UPDATE CODE FOR CHANGES HERE */ 

  Lists: List[];
  ListFolderLoadComplete: boolean;
  ListFolderDataLoaded: boolean;
  ListFolderPropertiesLoaded: boolean;
  private dataRefreshSubscription: Subscription;

  constructor (id, title, deleted, userId, expanded) {
    this.id = id;
    this.title = title;
    this.deleted = deleted;
    this.userId = userId;
    this.expanded = expanded;
    
    this.Lists = [];
    this.ListFolderPropertiesLoaded = false;
    this.ListFolderLoadComplete = false;
    this.ListFolderDataLoaded = false;

    /*this.dataRefreshSubscription = TriggerDataRefreshEvent.subscribe(ref => {
    try {
      if (!this.ListFolderPropertiesLoaded) {
        let inCompleteCount = 0;
        this.Lists.forEach(list =>  {if (!list.ListLoadCompleted) inCompleteCount++ });

        if (inCompleteCount == 0) {
          this.ListFolderPropertiesLoaded = true;
          ListFolder.Log("**List folder properties loaded for "+this.id);
        }
      }
      if (!this.ListFolderLoadComplete) {
        if (this.ListFolderDataLoaded && this.ListFolderPropertiesLoaded)
          this.ListFolderLoadComplete = true;
          ListFolder.Log("**List folder load complete for "+this.id);
      }
    }
    catch {}
    });*/
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.destroy();
  }
  
  destroy() {
    this.Lists.forEach (list => list.destroy());
    this.Lists = null;
    this.dataRefreshSubscription.unsubscribe();
  }

  
  toString() {
    return "id: " + this.id + ", title: "+this.title;
  }

  static Log(message: string): void {
    console.log("> ListFolder: " + message)
  }

  //static async GetListFolderFromValues(values): Promise<ListFolder> {
  static GetListFolderFromValues(values, loadListProperties: boolean, callback): ListFolder {
    //return new Promise((resolve, reject) => {
      try {
        let tempListFolder = new ListFolder(values.id, values.title, values.deleted, values.userId, values.expanded);
        //tempListFolder.loadProperties().then(() => {
          //tempListFolder.ListFolderDataLoaded = true;
          tempListFolder.loadProperties(loadListProperties, callback);

          TriggerDataRefreshEvent.emit("listFolder");
          ListFolder.Log("List folder loaded - "+tempListFolder.title);
          //resolve(tempListFolder);
          return tempListFolder;

        //}).catch(() => { resolve(null) });

      }
      catch(err) {
        ListFolder.Log("Error loading list folder: " + err);
        return null;
        //reject("Error loading list folder: " + err);
      }
    //});
  }

  //async loadProperties(): Promise<boolean> {
  loadProperties(loadListProperties: boolean, callback) {
    //return new Promise((resolve, reject) => {
      try {

        this.Lists = [];
        database.ref(Constants.DB_LISTS).orderByChild("folderId").equalTo(this.id).once("value").then(snapshot => {
          snapshot.forEach(item => {
            ListFolder.Log("Found a list "+item.val().title+" for folder "+this.title);

            /*List.GetListFromValues(item.val()).then(list => {
              if (list) {
                this.Lists.push(list as List);
              }
            });*/

            //let list = List.GetListFromValues(item.val(), loadListProperties, () => this.listCallback(callback));
            List.GetListFromValues(item.val(), false).then(list => { 
              if (list) this.Lists.push(list);
              
              TriggerDataRefreshEvent.emit("list folder");
            });
          });
        }).finally(() => {
          //ListFolder.Log("**List folder properties loaded");
          //this.ListFolderPropertiesLoaded = true;
          //this.ListFolderLoadComplete = true;
          if (!loadListProperties) {
            this.ListFolderLoadComplete = true;
            if (callback)
              callback();
          }
          else
            this.listCallback(callback);
        });



        //}).then(() => {
        //  resolve(true);
        //}).catch(() => {resolve(false)});

      }
      //catch(err) {reject(err)}
      catch(err) { return null };
    //});
  }

  listCallback(callback) {
    //ListFolder.Log("list callback within list folder");
    if (!this.ListFolderPropertiesLoaded) {
      let inCompleteCount = 0;
      this.Lists.forEach(list =>  {if (!list.ListLoadCompleted) inCompleteCount++ });
      //this.ListFolders.forEach(folder => { if(!folder.ListFolderLoadComplete) inCompleteCount++ });

      if (inCompleteCount == 0) {
        this.ListFolderPropertiesLoaded = true;
        ListFolder.Log("**List folder properties loaded");
      }
    }
    if (!this.ListFolderDataLoaded && this.ListFolderPropertiesLoaded) {
      this.ListFolderLoadComplete = true;
      ListFolder.Log("**List folder load complete");
      if (callback)
        callback();
    }
  }

  async updateServer(updateProperties: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        ListFolder.Log("Updating list folder "+this.title);
        TriggerDataRefreshEvent.emit("listFolder");
        if (this.id == "0" || this.id == "-1")
          this.id = AFSDB.createId();
    
        database.ref(Constants.DB_LIST_FOLDERS+"/"+this.id).update({
          id: this.id,
          title: this.title,
          deleted: this.deleted,
          userId: this.userId,
          expanded: this.expanded,
        }).then(() => {

          if (updateProperties) {
            //this.Lists.forEach(list => list.updateServer(true));
            let pos = 1;
            this.Lists.sort(function(a, b) {return (a.position - b.position)}).forEach(list => {
              if (!list.deleted)
                list.position = pos++;
              list.updateServer(true);
            });
          }

        }).finally(() => {
          resolve(true);
        }).catch(() => { resolve(false) });
    
      }
      catch(err) {reject(err)}
    });
  }

  async updateServer_ListDataOnly(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {

        //this.Lists.forEach(list => list.updateServer(false));
        let pos = 1;
        this.Lists.sort(function(a, b) {return (a.position - b.position)}).forEach(list => {
          if (!list.deleted)
            null;//list.position = pos++;
          list.updateServer(false);
        });


        resolve(true);

      }
      catch (err) { reject(err) }
    });
  }

  delete(deleteListsWithin: boolean) {
    ListFolder.Log("Deleting list folder "+this.title);

    this.Lists.forEach(list => {
      list.deleted = true;
    });

    this.deleted = true;
    
    this.updateServer(true);
  }

  getLists(): List[] {
    //ListFolder.Log("TEMPLATE FUNCTION CALL - getLists");
    return this.Lists.filter(list => !list.deleted).sort(function(a, b) {return (a.position - b.position)});
  }
 
}