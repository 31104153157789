import { FirebaseDatabase } from 'angularfire2';
import { AngularFirestore } from 'angularfire2/firestore';
import { EventEmitter, Injectable } from '@angular/core';
import { AngularFireStorage } from 'angularfire2/storage';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';


export var database: FirebaseDatabase;
export var AFSDB: AngularFirestore;
export var TriggerDataRefreshEvent: EventEmitter<string>;

@Injectable({
    providedIn: 'root'
  })

export class Constants {

    constructor(public db: AngularFireStorage, public afAuth: AngularFireAuth, public afsdb: AngularFirestore) {
        database = firebase.database();
        AFSDB = afsdb;
        console.log("Initiated for version "+Constants.VERSION);
        console.log("DB lists: "+Constants.DB_LISTS);
        //this.UserLoadedEvent = new EventEmitter<User>();
        //this.UserCreatedEvent = new EventEmitter<User>();
        TriggerDataRefreshEvent = new EventEmitter();
    }

    static readonly PROJECTNAME = "TODO";
    static readonly VERSION = "0.3";
    static readonly COPYRIGHT = "KIFFBRU SOFTWARE LTD";
    
    static readonly DATE_FORMAT = 'dd MMM yyyy';
    static readonly TIME_FORMAT = 'HH:mm';
    static readonly DATE_TIME_FORMAT = `${Constants.DATE_FORMAT} ${Constants.TIME_FORMAT}`;
    static readonly TIME_DATE_FORMAT = `${Constants.TIME_FORMAT} ${Constants.DATE_FORMAT}`;

    static readonly DB_LISTS = "lists_03_dev";
    static readonly DB_LIST_FOLDERS = "list_folders_03_dev";
    static readonly DB_USERS = "users_03_dev";
    static readonly DB_COLUMNS = "columns_03_dev";
    static readonly DB_ROWS = "rows_03_dev";
    static readonly DB_CELLS = "cells_03_dev";
    //static readonly DB_LISTS = "lists_merged";

    public static Fonts = {
        Logo: "",
        Body: "",
    }

    public static FontStyles = {
        HeaderSize: "",
        BodySize: "",
    }

    public static Colours = {       //Pallet 6
        PageBackground: "#344660",  
        PageBackgroundAccent1: "#152033",
        
        ControlBackground: "#EEF5FB",
        ListHeaderBackground: "#00BBCC",

        RowBackground: "#EEF5FB",          
        RowBackgroundDarker1: "#E5F0F9",
        RowBackgroundDarker2: "#DCEBF7",
        RowBackgroundDarker3: "#D4E7F6",

        
        TextDark: "#344660",        
        TextLight: "#F4FAFF",
        Selection: "#C1DBF0",

        Good: "#008A5A",        //Green
        Bad: "#984C63",         //Dark Red
        Caution: "#EA9A27",     //Orange
        Warning: "#D00000",     //Bright red
    };

    /*
    public static Colours = {       //Pallet 5
        PageBackground: "#519DD9",
        PageBackgroundAccent1: "#247DB6",
        
        Background: "#F6FAFD",
        BackgroundDarker1: "#EEF5FB",
        BackgroundDarker2: "#E5F0F9",
        BackgroundDarker3: "#DCEBF7",
        BackgroundDarker4: "#D4E7F6",
        BackgroundDarker5: "#CBE2F4",
        
        Text: "#3B4856",
        Selection: "#009BF9",

        Good: "#5EA760",        //Green
        Bad: "#EC7177",         //Red
        Caution: "#E8765C",     //Orange
        Warning: "#F9F871",     //Yellow
    };*/

    
}