import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants } from './constants';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == -1)
      return "-"
    try {
      if (args == "date")
        return super.transform(value, Constants.DATE_FORMAT);
      else if (args == "time")
        return super.transform(value, Constants.TIME_FORMAT);
      else if (args == "datetime")
        return super.transform(value, Constants.DATE_TIME_FORMAT);
      else if (args == "datetime")
        return super.transform(value, Constants.TIME_DATE_FORMAT);
      else
        return super.transform(value, Constants.DATE_FORMAT);
    }
    catch {
      return "-";
    }
  }

}
