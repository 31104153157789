import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators, PatternValidator} from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { TodoDBService } from '../todoDB.service';
import { TriggerDataRefreshEvent } from '../constants';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserLoginComponent implements OnInit { 
  loginOrCreate: string = "";

  private navigationSubscription: Subscription;
  private dataRefreshSubscription: Subscription;

  constructor(public todoDBService: TodoDBService, public changeDetectorRef: ChangeDetectorRef,
    public route: ActivatedRoute, private router: Router) {

    this.dataRefreshSubscription = TriggerDataRefreshEvent.subscribe(ref => {
      //this.changeDetectorRef.detectChanges(); //Update template for changes
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.loadComponent();
        console.log("NAVIGATION CHANGED");
      }
    });

  }

  ngOnInit(): void {
    this.loadComponent();
  }

  ngOnDestroy() {
    this.dataRefreshSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  loadComponent() {
    const action = this.route.snapshot.paramMap.get('action'); 

    if (!action && !this.todoDBService.CurrentUser) {
      this.loginForm();
    }
    else if (action && this.todoDBService.CurrentUser) {
      this.profileForm();
    }

    if (action == "new" && !this.todoDBService.CurrentUser) {
      this.createForm();
    }
    else if (action == "login" && !this.todoDBService.CurrentUser) {
      this.loginForm();
    }
    else if (action == "logout") {
      this.logout();
    }
    else if (!this.todoDBService.CurrentUser) {
      this.loginForm();
    }
    else {
      this.profileForm();
    }

 
  }



  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  requiredFormControl = new FormControl('', [
    Validators.required
  ]);

  logout(): void {
    /*this.todoService.afAuth.auth.signOut().then(ref => {
      this.todoService.CurrentUser = null;
    }).catch(err => {
      console.log("Error signing out: "+err);
    });*/
    this.todoDBService.currentUserLogout().then(() => this.todoDBService.displayProgressBar = false);
    TriggerDataRefreshEvent.emit("user");
  }

  loginForm(): void {
    this.loginOrCreate = "login";
    TriggerDataRefreshEvent.emit("user");
  }

  createForm(): void {
    this.loginOrCreate = "create";
    TriggerDataRefreshEvent.emit("user");
  }

  profileForm(): void {
    this.loginOrCreate = "";
    TriggerDataRefreshEvent.emit("user");
  }

  cancel(): void {
    this.loginOrCreate = "";
    TriggerDataRefreshEvent.emit("user");
  }

  createAccount(name: string, email: string, password: string): void {
    let regex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
    if (!regex.test(email)) //Check email validity
      return;
    if (name == "" || email == "" || password == "")
      return;
    
    /*
    this.todoService.afAuth.auth.createUserWithEmailAndPassword(email, password).then(ref => {
      console.log("User created! "+ref.user.email);
      this.todoService.CurrentUser = new User(ref.user.uid, name, ref.user.email);
      //this.todoService.updateUserOnServer(this.todoService.CurrentUser);
    }).catch(err => {
      console.log("Error creating user: " +err.code+" - "+err.message);
    });*/
    this.todoDBService.displayProgressBar = true;
    this.todoDBService.createUserAccount(name, email, password).then(() => this.todoDBService.displayProgressBar = false);
    this.loginOrCreate = "";
    TriggerDataRefreshEvent.emit("user");
  }

  login(email: string, password: string): void {
    this.loginOrCreate = "loading user";
    TriggerDataRefreshEvent.emit("user");
    let regex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$");
    if (!regex.test(email)) //Check email validity
      return;
    if (email == "" || password == "")
      return;
    /*this.todoService.afAuth.auth.signInWithEmailAndPassword(email, password).then(ref => {
      console.log("Logged in! " + ref.user.email);
      this.todoService.CurrentUser = new User("-1", "Bob", ref.user.email);
    }).catch(err => {
      console.log("Error logging in: " +err.code+" - "+err.message);
    });*/
    //-this.todoService.loginUser(email, password);
    this.todoDBService.displayProgressBar = true;
    this.todoDBService.loginUser(email, password).then(() => this.todoDBService.displayProgressBar = false);
    //this.loginOrCreate = "";
    TriggerDataRefreshEvent.emit("user");
  }
}
