<div class="page-base">
    <div class="page-header">
        About <span class="logo-font-style">exenea</span>
    </div>

    <!-- 
    <table style="width: 100%"> <!-- Or flexbox? --
        <tr valign="top">
            <td style="width: 50%">
                <div>
                    <p>
                        Exenea aims to be a new force in the world of productivity management. 
                    </p>

                    <p>   
                        At it's core, Exenea is a list management platform, but it is so much more than just that. 
                    </p>
                        
                    <p>
                        Exenea will allow you to create lists with customised layouts so that you can keep track of all necessary information relating 
                        to your projects, for example by adding columns to represent the status of various lifecycles of each item within the list. 
                        
                    </p>

                    <p>
                        Lists can be made shareable in order for others to collaborate on projects with you, and permissions on lists will be at a 
                        very granular level to give you maximum control. 
                    </p>

                    <p>    
                        Visit the <a (click)="this.router.navigate(['examples']);">examples page</a> for some scenarios in which Exenea would be useful.    
                    </p>

                    <div class="global-notice-attention-green">

                        <p>
                            Exenea is currently under development, and an alpha test version will soon be available. Updates will be provided regularly in the <a (click)="this.router.navigate(['news']);">news section</a> and Exenea's social media pages.
                        </p>

                        <p>    
                            If you are interested in signing up, please <a (click)="this.router.navigate(['home']);">sign up for the newsletter</a>. Alternatively, you can follow Exenea's 
                            social media pages for future updates. You can also email <a href="mailto:hello@exenea.com?subject=Hello Exenea!">hello@exenea.com</a>  if you have any comments or questions. 
                        </p>
                    </div>
                    
                </div>
            </td>
            <td style="width: 50%">
                <div class="examples-container">
                    <img class="screenshot-container" src="assets/0.3p-todo.PNG"/>
                    <div>An example of a simple to do list in Exenea</div>
                </div>
            </td>
        </tr>
    </table>-->

    <div class="flex-container">
        <div class="flex-text">
            <div>
                <p>
                    Exenea aims to be a new force in the world of productivity management. 
                </p>

                <p>   
                    At it's core, Exenea is a list management platform, but it is so much more than just that.
                </p>
                    
                <p>
                    Exenea will allow you to create lists with customised layouts so that you can keep track of all necessary information relating 
                    to your projects, for example by adding columns to represent the status of various lifecycles of each item within the list.
                </p>

                <p>
                    Exenea is easy to use and avoids complexity wherever possible.
                </p>

                <p>
                    Lists can be made shareable in order for others to collaborate on projects with you, and permissions on lists will be at a 
                    very granular level to give you maximum control. 
                </p>

                <p>    
                    Visit the <a (click)="this.router.navigate(['examples']);">examples page</a> for some scenarios in which Exenea would be useful.    
                </p>

                <div class="global-notice-attention-green">

                    <p>
                        Exenea is currently under development, and an alpha test version will soon be available. Updates will be provided regularly in the <a (click)="this.router.navigate(['news']);">news section</a> and Exenea's social media pages.
                    </p>

                    <p>    
                        If you are interested in signing up, please <a (click)="this.router.navigate(['home']);">sign up for the newsletter</a>. Alternatively, you can follow Exenea's 
                        social media pages for future updates. You can also email <a href="mailto:hello@exenea.com?subject=Hello Exenea!">hello@exenea.com</a>  if you have any comments or questions. 
                    </p>
                </div>
                
                <br>
            </div>
        </div>
        <div class="flex-image">
            <div class="examples-container">
                <img class="screenshot-container" src="assets/0.3p-todo.PNG"/>
                <div>An example of a simple to do list in Exenea</div>
            </div>
        </div>
    </div>

    
</div>