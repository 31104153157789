<div class="page-base">
    <div class="page-header">
        Welcome to <span class="logo-font-style">exenea</span>
    </div>

    <!--<table style="width: 100%"> <!-- Or flexbox? --
        <tr valign="top">
            <td style="width: 50%">
                <div>
                    <p>
                        Exenea aims to be a new force in the world of productivity management. 
                    </p>

                    <p>   
                        Born out of a lack of customisability and control in most productivity platforms, Exenea is much more than just a list manager, with the aim of improving your productivity by giving you increased insight into your projects and to-do lists in both professional and personal environments.  
                    </p>

                    <div class="global-notice-attention-green">
                        <p>
                            Exenea is currently under development and an alpha test version will soon be available for public testing. Sign up below or on our social media pages if you are interested in keeping up to date on Exenea.
                        </p>
                    </div>

                    <iframe width="540" height="700" src="https://dc8941b8.sibforms.com/serve/MUIEAGU11psr0vtWk7bMZnmRtYwoo63c6cFaTeV2anyvzcTP_KdQFMT9COi78J5e_9i8QCwQyEc1qOwIX4QDbFoOWny4v21WyB8pLUf-6VJyVBl49ue_2dLuv9WJntdw-zBjRjNsDYhezO3rkBGXbD-YWvtKEPJBT-RbrOJsOVXum1tdK730kJRpU6uYwLmNeCB3PUhcLmmLXdE2" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
                    
                </div>
            </td>
            <td style="width: 50%">
                <div class="examples-container">
                    <img class="screenshot-container" src="assets/0.3p-BPT.PNG"/>
                    <div>An example of a project tracker in Exenea</div>
                </div>
            </td>
        </tr>
    </table>-->

    <div class="flex-container">
        <div class="flex-text">
            <div>
                <p>
                    Exenea aims to be a new force in the world of productivity management. 
                </p>

                <p>   
                    Born out of a lack of customisability and control in most productivity platforms, Exenea is much more than just a list manager, with the aim of improving your productivity by giving you increased insight into your projects and to-do lists in both professional and personal environments, while avoiding complexity and maintaining ease of use. 
                </p>

                <div class="global-notice-attention-green">
                    <p>
                        Exenea is currently under development and an alpha test version will soon be available for public testing. Sign up below or on our social media pages if you are interested in keeping up to date on Exenea.
                    </p>
                </div>

                 <!--   -->
                <iframe width="540" height="800" 
                src="https://dc8941b8.sibforms.com/serve/MUIEAJ7DoT297DbuR9xszlzzdkjyy_7Wn5fxUb_dOMECqWE3Mo797VDg_cACBRjs9FTKLB52zm5cnO9ID4uscHDC_AxIQsjWq1eH5602HyVtDCXUPe-ekmDwh4q9NdXYUBMswlesMmHo_OseyqGHBMEHVo3KFlDaiHXl5N390SF1A3oGsmK-_xqhqXQYhmr-DUABJIPNPG8cqi7e" 
                scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%; border: 0px"></iframe>

            </div>
        </div>
        <div class="flex-image">
            <div class="examples-container">
                <img class="screenshot-container" src="assets/0.3p-BPT.PNG"/>
                <div>An example of a project tracker in Exenea</div>
            </div>
        </div>
    </div>
    
</div>